export const celularMask = value => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1')
}

export const cepMask = value => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1')
}

export const phoneMask = (value) => {
  return value
    .replace(/\D/g,'')
    .replace(/(\d{2})(\d)/,"($1) $2")
    .replace(/(\d)(\d{4})$/,"$1-$2")
}

export const cnpjMask = (v) => {
  v = v.replace(/\D/g, "")

  if (v.length <= 11) {
    v = v.replace(/(\d{3})(\d)/, "$1.$2")
    v = v.replace(/(\d{3})(\d)/, "$1.$2")
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
  } else {
    v = v.replace(/^(\d{2})(\d)/, "$1.$2")
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
    v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")
    v = v.replace(/(\d{4})(\d)/, "$1-$2")
  }

  return v
}

export const currencyMask = value => {
  value = value + '';
  value = parseInt(value.replace(/[\D]+/g, ''));
  value = value + '';
  value = value.replace(/([0-9]{2})$/g, ",$1");

  // if (value.length > 6) {
  //   value = value.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
  // }

  if(value == 'NaN') return '';

  return value
}

export const dateMask = (val) => {
  let pass = val;
  let expr = /[0123456789]/;

  for (let i = 0; i < pass.length; i++) {
    var lchar = val.charAt(i);
    var nchar = val.charAt(i + 1);

    if (i == 0) {
      if ((lchar.search(expr) != 0) || (lchar > 3)) {
        val = "";
      }

    } else if (i == 1) {

      if (lchar.search(expr) != 0) {
        var tst1 = val.substring(0, (i));
        val = tst1;
        continue;
      }

      if ((nchar != '/') && (nchar != '')) {
        var tst1 = val.substring(0, (i) + 1);

        if (nchar.search(expr) != 0)
          var tst2 = val.substring(i + 2, pass.length);
        else
          var tst2 = val.substring(i + 1, pass.length);

        val = tst1 + '/' + tst2;
      }

    } else if (i == 4) {

      if (lchar.search(expr) != 0) {
        var tst1 = val.substring(0, (i));
        val = tst1;
        continue;
      }

      if ((nchar != '/') && (nchar != '')) {
        var tst1 = val.substring(0, (i) + 1);

        if (nchar.search(expr) != 0)
          var tst2 = val.substring(i + 2, pass.length);
        else
          var tst2 = val.substring(i + 1, pass.length);

        val = tst1 + '/' + tst2;
      }
    }

    if (i >= 6) {
      if (lchar.search(expr) != 0) {
        var tst1 = val.substring(0, (i));
        val = tst1;
      }
    }
  }

  if (pass.length > 10)
    val = val.substring(0, 10);

  return val;
}

export const numberMask = (value) => {
  if(Number(value) < 0) return 0
  if(Number(value) > 1000) return 10000
  return Math.ceil(Number(value))
}