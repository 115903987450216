import React from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
// import { RouterProvider } from 'react-router-dom';
// import router from 'routes/routes';
import './index.css';

import AuthGuard from 'services/auth.guard';
import AdminLayout from 'components/AdminLayout';

import LoginPage from 'pages/Login';
import Dashboard from 'pages/Dashboard';
import Vendas from 'pages/Vendas';
// import Settings from 'pages/Settings';
import NotFoundPage from 'pages/NotFound';
import Clientes from 'pages/Clientes';
import Endereco from 'pages/Endereco';
import Consulta from 'pages/Consulta';
import { NotificationProvider } from 'context/notification';
import NotificationBar from 'components/Notification';
import Spinner from 'components/Spinner';
import Detalhes from 'pages/Detalhes';
import Locacao from 'pages/Locacao';
import ConsultaXaaS from 'pages/ConsultaXaaS';
import DetalhesXaaS from 'pages/DetalhesXaaS';
import SocQuote from 'pages/SocQuote';
import Soc from 'pages/Soc';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <NotificationProvider>
      <NotificationBar />
      <Routes>
        <Route path='/login' element={<LoginPage />} />

        <Route element={<AuthGuard />}>

          <Route element={<AdminLayout />}>
            <Route index element={<Dashboard />} />

            <Route path='clientes'>
              <Route path='cadastro' element={<Clientes />} />
              <Route path='enderecos' element={<Endereco />} />
            </Route>

            {/* <Route path='vendas/consultar/:id' element={<Detalhes />} />
            <Route path='vendas/consultar' element={<Consulta />} />
            <Route path='vendas/cotacao' element={<Vendas />} /> */}

            <Route path='xaas/consultar/:id' element={<DetalhesXaaS />} />
            <Route path='xaas/consultar' element={<ConsultaXaaS />} /> 
            <Route path='xaas/cotacao' element={<Locacao />} />

            <Route path='soc/cotacao' element={<Soc />} />
            <Route path='soc/consultar' element={<ConsultaXaaS />} /> 

            {/* <Route path='settings' element={<Settings />} /> */}
          </Route>
        </Route>

        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </NotificationProvider>
    {/* <Spinner /> */}
  </BrowserRouter>
);
