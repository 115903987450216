import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import propostaService from 'services/proposta.service';
import authService from "services/auth.service"

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export default function Step3({ pedido, prev }) {
  const authUser = authService.getAuthUser();

  const navigate = useNavigate();
  const [impressao, setImpressao] = useState('')

  const exportPdf = () => {
    document.querySelector("#test").style.display = 'none'
    window.scrollTo(0, 0)
    html2canvas(document.querySelector("#capture")).then(canvas => {
      const img = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: 'l',
        unit: 'px',
        format: [canvas.width * 0.565, canvas.height * 0.75]
      });
      pdf.addImage(img, 'PNG', 0, 0);
      pdf.save("download.pdf");
    });
    document.querySelector("#test").style.display = 'inherit'
    // window.scrollTo(0,0)
    // html2canvas(document.querySelector("#capture")).then(canvas => {
    //   const img = canvas.toDataURL("image/png");
    //   const pdf = new jsPDF({
    //     orientation: 'p',
    //     unit: 'px',
    //     format: [canvas.width * 0.57, canvas.height * 0.75]
    //   });
    //   pdf.addImage(img, 'PNG', 0, 0);
    //   pdf.save("download.pdf");
    // });
  }

  const getImpressao = async () => {
    const response = await propostaService.impressao({
      "recnum": pedido.recnum
    });
    setImpressao(response.data)
  }

  const liberarPedido = async () => {
    const confirm = window.confirm('Deseja aprovar o pedido?')
    if (confirm) {
      await propostaService.aprovar({
        "recnum": pedido.recnum,
      });
      alert('aprovado')
      navigate('/soc/consultar')
    }
  }

  const baixaPedido = async () => {
    exportPdf()
  }

  useEffect(() => {
    getImpressao()
  }, [])

  return (
    <>
      <form className="space-y-6" action="#" method="POST">
        <div id="capture" className="shadow sm:rounded-md">

          <div className="space-y-6 bg-white py-6 px-4 sm:p-6 ">

            <div className="grid grid-cols-6 gap-6">


              <div id="test" className="sm:col-span-6">
                <div className="flex items-center justify-between pt-2">
                  <div>
                    <h3 className="text-base font-semibold leading-6 text-gray-900">Resumo </h3>
                    <p className="mt-1 text-sm text-gray-500">Baixe ou aprove sua proposta.</p>
                  </div>

                  <div>
                    <button
                      type="button"
                      className="mr-3 w-[150px] inline-flex justify-center items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={baixaPedido}
                    >
                      Baixar Cotação
                    </button>
                    <button
                      type="button"
                      className="w-[150px] inline-flex justify-center  items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={liberarPedido}
                    >
                      Aprovar Proposta
                    </button>
                  </div>
                </div>
              </div>


              <div className="col-span-6 sm:col-span-6">
                {impressao ?
                  <div className="px-4 pb-4" >
                    <div className='grid grid-cols-6 gap-6 bg-gray-50 h-[50px] text-sm mb-3 rounded-t-md'>
                      <div className='col-span-6 sm:col-span-6 flex justify-center items-center'>
                        <span className='font-bold'>{impressao.titulo}</span>
                      </div>
                    </div>
                    <div className='flex justify-between'>
                      <div className='text-sm flex flex-col'>
                        <span className='text-sm'><strong>CISP1 SOLUCOES DE TECNOLOGIA LTDA</strong></span>
                        <span><strong>CNPJ:</strong> 48.592.073/0001-46 - <strong>IE: 138.301.630.113</strong></span>
                        <span><strong>Endereço:</strong> AVENIDA BRIG FARIA LIMA , 4055, ITAIM BIBI - SAO PAULO / SP - <strong>CEP:</strong> 04538-133</span>
                        <span><strong>Telefone:</strong> (11) 2714-6900 </span>
                      </div>
                      <img
                        className="h-[50px] w-auto"
                        src={'/logo.png'}
                        alt="CISP1"
                      />
                    </div>
                    <div className="grid grid-cols-6 gap-6 text-sm mt-10">
                      <div className="col-span-6 sm:col-span-3 flex flex-col">
                        <span><strong>Razão Social:</strong> {impressao.cliente[1].split(':')[1]}</span>
                        <span><strong>CNPJ:</strong> {impressao.cliente[2].split(':')[1]}</span>
                        <span><strong>IE:</strong> {impressao.cliente[3].split(':')[1]}</span>
                        <span><strong>Telefone:</strong> {impressao.cliente[7].split(':')[1]}</span>
                      </div>
                      <div className="col-span-6 sm:col-span-3 flex flex-col">
                        <span><strong>Endereço:</strong> {impressao.cliente[4].split(':')[1]}</span>
                        <span><strong>Município:</strong> {impressao.cliente[5].split('-')[0].split(':')[1]}</span>
                        <span><strong>UF:</strong> {impressao.cliente[5].split('-')[1].split(':')[1]}</span>
                        <span><strong>CEP:</strong> {impressao.cliente[6].split(':')[1]}</span>
                      </div>
                    </div>
                    <div className="grid grid-cols-6 gap-6 text-sm mt-10">
                      <div className="col-span-6 sm:col-span-3"><strong>Condição de Pagamento:</strong> {impressao.condicao[1].split(':')[1]}</div>
                      {/* <div className="col-span-6 sm:col-span-3"><span><strong>Periodo:</strong> {impressao.condicao[0].split(':')[1]}</span></div> */}
                    </div>

                    {impressao.subscricao.produtos.length > 0 &&
                      <>
                        <div className="text-sm text-center mt-10 flex">
                          <strong>Produtos em Subscrição Mensal</strong>
                        </div>
                        <div className="mt-5 flow-root">
                          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300 table-fixed">
                                  <thead className="bg-gray-50">
                                    <tr>
                                      <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900 text-center">
                                        Item
                                      </th>
                                      <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900 text-center">
                                        Codigo
                                      </th>
                                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Descricao
                                      </th>
                                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Qtde
                                      </th>
                                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Valor
                                      </th>
                                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Total
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody className="divide-y divide-gray-200 bg-white">
                                    {impressao.subscricao.produtos.map((produtos) => (
                                      <tr key={produtos.sku}>
                                        <td className="text-center whitespace-nowrap text-sm text-gray-500">{produtos.id}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{produtos.sku}</td>
                                        <td className="whitespace-nowrap truncate max-w-[250px] px-3 py-4 text-sm text-gray-500">{produtos.descricao}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{produtos.quantidade}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{produtos.valor}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">{produtos.total_produto}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <th></th>
                                      <th></th>
                                      <th className="text-left whitespace-nowrap px-3 py-2.5 text-sm text-gray-700">Total Recorrente:</th>
                                      <th></th>
                                      <th></th>
                                      <th className="whitespace-nowrap px-3 py-2.5 text-sm text-gray-700 text-left">{impressao.subscricao.total}</th>
                                    </tr>
                                  </tfoot>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    }

                    {impressao && impressao.obs.office.devices.quantity &&
                      <>
                        <div className="text-sm text-center mt-8 flex">
                          <strong>SoC As a Services Microsoft</strong>
                        </div>
                        <div className="mt-5 flow-root">
                          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                  <thead className="bg-gray-50">
                                    <tr className='grid grid-cols-10'>
                                      <th scope="col" className="col-span-6 px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                        Serviço
                                      </th>
                                      <th scope="col" className="col-span-2 px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                        Usuários                                    </th>
                                      <th scope="col" className="col-span-1 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        EPS
                                      </th>
                                      <th scope="col" className="col-span-1 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        GB/day
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="divide-y divide-gray-200 bg-white">
                                    <tr className='grid grid-cols-10' key={`office`}>
                                      <td className="col-span-6 text-center px-3 py-4 whitespace-nowrap text-sm text-gray-500">Office 365</td>
                                      <td className="col-span-2 whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{impressao.obs.office.devices.quantity}</td>
                                      <td className="col-span-1 whitespace-nowrap truncate max-w-[250px] px-3 py-4 text-sm text-gray-500">{impressao.obs.office.devices.eps}</td>
                                      <td className="col-span-1 whitespace-nowrap px-3 py-4 text-sm text-gray-500">{impressao.obs.office.devices.gb}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    }

                    {impressao && impressao.obs.analytics.devices.length > 0 &&
                      <>
                        <div className="text-sm text-center mt-8 flex">
                          <strong>SoC As a Services Analytics & Reports</strong>
                        </div>
                        <div className="mt-5 flow-root">
                          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                  <thead className="bg-gray-50">
                                    <tr className='grid grid-cols-10'>
                                      <th scope="col" className="col-span-6 px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                        Devices
                                      </th>
                                      <th scope="col" className="col-span-2 px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                        Qtde
                                      </th>
                                      <th scope="col" className="col-span-1 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        EPS
                                      </th>
                                      <th scope="col" className="col-span-1 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        GB/day
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="divide-y divide-gray-200 bg-white">
                                    {impressao.obs.analytics.devices.map((produtos) => (
                                      <tr className='grid grid-cols-10' key={produtos.id}>
                                        <td className="col-span-6 text-center px-3 py-4 whitespace-nowrap text-sm text-gray-500">{produtos.nome}</td>
                                        <td className="col-span-2 whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{produtos.quantity}</td>
                                        <td className="col-span-1 whitespace-nowrap truncate max-w-[250px] px-3 py-4 text-sm text-gray-500">{produtos.eps}</td>
                                        <td className="col-span-1 whitespace-nowrap px-3 py-4 text-sm text-gray-500">{produtos.gb}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    }


                    {impressao && impressao.obs.light.devices.length > 0 &&
                      <>
                        <div className="text-sm text-center mt-8 flex">
                          <strong>SoC As a Services Light</strong>
                        </div>
                        <div className="mt-5 flow-root">
                          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                  <thead className="bg-gray-50">
                                    <tr className='grid grid-cols-10'>
                                      <th scope="col" className="col-span-6 px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                        Devices
                                      </th>
                                      <th scope="col" className="col-span-2 px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                        Qtde
                                      </th>
                                      <th scope="col" className="col-span-1 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        EPS
                                      </th>
                                      <th scope="col" className="col-span-1 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        GB/day
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="divide-y divide-gray-200 bg-white">
                                    {impressao.obs.light.devices.map((produtos) => (
                                      <tr className='grid grid-cols-10' key={produtos.id}>
                                        <td className="col-span-6 text-center px-3 py-4 whitespace-nowrap text-sm text-gray-500">{produtos.nome}</td>
                                        <td className="col-span-2 whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{produtos.quantity}</td>
                                        <td className="col-span-1 whitespace-nowrap truncate max-w-[250px] px-3 py-4 text-sm text-gray-500">{produtos.eps}</td>
                                        <td className="col-span-1 whitespace-nowrap px-3 py-4 text-sm text-gray-500">{produtos.gb}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    }

                    {impressao && impressao.obs.full.devices.length > 0 &&
                      <>
                        <div className="text-sm text-center mt-8 flex">
                          <strong>SoC As a Services Full</strong>
                        </div>
                        <div className="mt-5 flow-root">
                          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                  <thead className="bg-gray-50">
                                    <tr className='grid grid-cols-10'>
                                      <th scope="col" className="col-span-6 px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                        Devices
                                      </th>
                                      <th scope="col" className="col-span-2 px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                        Qtde
                                      </th>
                                      <th scope="col" className="col-span-1 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        EPS
                                      </th>
                                      <th scope="col" className="col-span-1 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        GB/day
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="divide-y divide-gray-200 bg-white">
                                    {impressao.obs.full.devices.map((produtos) => (
                                      <tr className='grid grid-cols-10' key={produtos.id}>
                                        <td className="col-span-6 text-center px-3 py-4 whitespace-nowrap text-sm text-gray-500">{produtos.nome}</td>
                                        <td className="col-span-2 whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{produtos.quantity}</td>
                                        <td className="col-span-1 whitespace-nowrap truncate max-w-[250px] px-3 py-4 text-sm text-gray-500">{produtos.eps}</td>
                                        <td className="col-span-1 whitespace-nowrap px-3 py-4 text-sm text-gray-500">{produtos.gb}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    }


                    <div className="grid grid-cols-6 gap-6 text-sm mt-5">
                      <strong className='col-span-6 sm:col-span-2'>Validade da Proposta: 14 dias</strong>
                    </div>

                  </div> :
                  <div className='mt-4 mb-4 flex justify-center'>
                    <svg aria-hidden="true" className="relative inline w-[60px] h-[60px] text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                    </svg>
                  </div>}
              </div>



            </div>
          </div>

        </div>
      </form>
      <div className="py-6 text-end ">

      </div>
      {/* <button
        type="button"
        className="mr-3 w-[150px] inline-flex justify-center  items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        onClick={prev}
      >
        Voltar
      </button> */}
    </>

  );
}
