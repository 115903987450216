import { useState } from 'react'
import Datepicker from "tailwind-datepicker-react"

const options = {
  autoHide: true,
  todayBtn: false,
  clearBtn: false,
  theme: {
    background: "",
    todayBtn: "",
    clearBtn: "",
    icons: "",
    text: "",
    disabledText: "",
    input: "",
    inputIcon: "",
    selected: "bg-indigo-600",
  },
  defaultDate: new Date(),
  language: "pt-BR",
  // weekDays: ["Seg", "Ter", "Qua", "Qui", "Sex", "Sab", "Dom"],
}

export default function DatePicker({ label, onChange }) {
  const [show, setShow] = useState(false)

  return (
    <>
      <div className="relative">
        <label className="block text-sm font-medium text-gray-900 mb-1">
          { label }
        </label>
        <Datepicker options={options} onChange={onChange} show={show} setShow={setShow} classNames="absolute cursor-pointer" />
      </div>
    </>
  )
}
