import { useState, useEffect } from 'react'
import { Transition } from '@headlessui/react'

export default function Clientes() {
  const [isShowing, setIsShowing] = useState(false)

  useEffect(() => {
    setTimeout(() => setIsShowing(true), 100);
  }, []);

  return (
    <Transition
      show={isShowing}
      enter="transition ease-in-out duration-[1000ms] transform"
      enterFrom="-translate-y-2 opacity-0"
      enterTo="translate-y-0 opacity-100"
      leave="transition ease-in-out duration-[1000ms] transform"
      leaveFrom="translate-y-0 opacity-100"
      leaveTo="-translate-y-2 opacity-0"
    >
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="pt-10 pb-16">
          <div className="px-4 sm:px-6 lg:px-0">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">Cadastrar Endereço</h1>
          </div>

        </div>
      </div>
    </Transition >
  )
}
