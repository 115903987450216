import { useState, useEffect } from 'react'
import { currencyMask } from 'utils/masks';
import QuoteBox from 'components/QuoteBox';
import DevicesAccordion from 'components/DevicesAccordion';

const categories = [
  { category: "Segurança" },
]

let devices = [
  {
    "id": 4,
    "nome": "Next Generation Firewall - P",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 50,
    "raw": 0,
    "category": "Segurança",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 2,
    "nome": "Next Generation Firewall - M",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 100,
    "raw": 0,
    "category": "Segurança",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 3,
    "nome": "Next Generation Firewall - G",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 240,
    "raw": 0,
    "category": "Segurança",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 1,
    "nome": "Next Generation Firewall - GG",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 240,
    "raw": 0,
    "category": "Segurança",
    "estimated": 0,
    "quantity": 0
  }
]

function logSizeDay(DEVICE_EPS_TOTAL) {
  let e = 0;
  return e = 86400 * DEVICE_EPS_TOTAL * 1500,
    e *= 9.313 * Math.pow(10, -10),
    e = Number(Math.round(e).toFixed(2)),
    e
}

export default function SoCLightQuote({ setGBs, setSelectedDevices, precoRevenda }) {
  const [isShowing, setIsShowing] = useState(false)
  const [devicesList, setDevicesList] = useState(devices)
  const [tz, setTz] = useState(false)
  const [nsa, setNsa] = useState(false)
  const [nssp, setNssp] = useState(false)
  const [totalEstimated, setTotalEstimated] = useState({})

  function manHoursValue() {
    let aux = 0
    if (tz) aux += ((133.33 * 1) * 1.42)
    if (nsa) aux += ((133.33 * 3) * 1.42)
    if (nssp) aux += ((133.33 * 6) * 1.42)
    return aux
  }

  const setDeviceInfo = (id, quantity) => {
    if (id == 1 && quantity > 0) {
      setNssp(true);
    } else if (id == 1 && quantity == 0) {
      setNssp(false);
    }

    if (id == 4 && quantity > 0) {
      setTz(true);
    } else if (id == 4 && quantity == 0) {
      setTz(false);
    }

    if ((id == 2 || id == 3) && quantity > 0) {
      setNsa(true)
    } else if ((id == 2 || id == 3) && quantity == 0) {
      setNsa(false)
    }

    let preco_custo = 1.08
    let markup = 1.852

    let preco_venda = (preco_custo * markup)

    let dias = 30
    let fator_paloni = 50

    let credito_per_giga = 25

    const newState = devicesList.map((device) => {
      if (device.id == id) {
        let eps = quantity * device.epsValue
        let gb = logSizeDay(eps)
        let estimated = (((gb * dias) / fator_paloni) * credito_per_giga) * preco_venda

        return {
          ...device,
          eps,
          gb,
          estimated,
          quantity
        }
      }

      return device
    })
    setSelectedDevices(newState.filter((device) => device.quantity > 0))
    setDevicesList(newState)
  }

  useEffect(() => {
    setTotalEstimated(devicesList.reduce(({ gb, cost, quantity }, device) => {
      return {
        gb: gb + Number(device.gb),
        cost: cost + Number(device.estimated),
        quantity: quantity + Number(device.quantity),
      }
    }, { gb: 0, cost: 0, quantity: 0 }))
  }, [devicesList]);

  useEffect(() => {
    precoRevenda(totalEstimated.quantity ? currencyMask((manHoursValue() + totalEstimated.cost).toFixed(2)) : 0)
    setGBs(totalEstimated.gb)
  }, [totalEstimated]);

  useEffect(() => {
    setTimeout(() => setIsShowing(true), 100);
  }, []);

  return (
    <div className="mx-auto max-w-6xl">
      <h2 className="text-lg font-bold text-gray-900">SoC As a Services Light - Calculadora</h2>
      <p className="mt-6 text-sm leading-8 text-gray-600">
        Get an estimate on the daily amount of data ingested from your infrastructure into your SIEM platform. The results can be exported as a PDF for your own use, or to get a quote.
      </p>

      <div className='grid grid-cols-12 gap-9 mt-6'>
        <div className="col-span-9">
          <DevicesAccordion
            devices={devicesList}
            categories={categories}
            setDeviceInfo={setDeviceInfo}
            defaultOpen={true}
          />
        </div>
        <QuoteBox
          gb={totalEstimated.gb}
          cost={totalEstimated.quantity ? currencyMask((manHoursValue() + totalEstimated.cost).toFixed(2)) : 0}
        />
      </div>

    </div>
  )
}
