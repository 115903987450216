//ToDo clear tokens on backend
import axios from "axios";
import authService from "services/auth.service";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

instance.interceptors.request.use(
  (config) => {
    const authUser = authService.getAuthUser();
    if (authUser) {
      config.headers["authorization"] = `Bearer ${authUser.access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error?.response?.status === 401) {
      //localStorage.removeItem("authUser");
      //window.location.reload();
    } else {
      return Promise.reject(error.response);
    }
  }
);

const logout = () => instance.get("/auth/logout", null, {
  transformResponse: [() => {

  },],
});

export default logout;
