import http from "utils/http-client";

const findAll = () => http.get("/cliente");
const findAddress = (id) => http.get(`/cliente/address/${id}`);
const findAddresses = (data) => http.post("/cliente/addresses", data);
const register = (data) => http.post("/cliente", data);
const getCNPJ = (data) => http.post("/cliente/cnpj", data);

const methods = {
  findAddress,
  findAddresses,
  findAll,
  register,
  getCNPJ
};

export default methods;
