import { useEffect, useState, useContext } from 'react';
import Select from 'components/Select';
import { TrashIcon } from "@heroicons/react/24/outline";
import AutoComplete from 'components/AutoComplete';
import propostaService from 'services/proposta.service';

import NotificationContext from 'context/notification';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import useDidMountEffect from 'hooks/use-did-mount-effect';

import { currencyMask, numberMask } from 'utils/masks';

import { usePromiseTracker } from "react-promise-tracker";
import Spinner from 'components/Spinner';

const categorias = [
  { id: 0, option: 'Todas' },
  { id: 1, option: 'Serviços Gerenciados' },
  { id: 19, option: 'Computação' },
]


const subCategorias = [
  { id: 0, option: 'Todas' },
  { id: 1, option: 'Serviços de Implementação' },
  { id: 2, option: 'Serviços de Suporte' },
  { id: 3, option: 'Serviços de NOC' },
  { id: 5, option: 'Serviços de Treinamento' },
  { id: 11, option: 'Desktop' },
  { id: 12, option: 'Notebook' },
  { id: 14, option: 'Monitores' }
]

const fabricantes = [
  { id: 0, option: 'Todos' },
  { id: 111, option: 'CISP1 Services' },
  { id: 112, option: 'Acer Empresas' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Step2({ next, prev, pedido }) {
  const notificationCtx = useContext(NotificationContext);
  const { promiseInProgress } = usePromiseTracker();

  const [produtos, setProdutos] = useState([])
  const [items, setItems] = useState([])
  const [valorFinal, setValorFinal] = useState(0)
  const [quantidade, setQuantidade] = useState(1)
  const [valorProduto, setValorProduto] = useState(undefined)
  const [produtoSelecionado, setProdutoSelecionado] = useState([])
  const [categoria, setCategoria] = useState('')
  const [subCategoria, setSubCategoria] = useState('')
  const [fabricante, setFabricante] = useState('')

  Yup.addMethod(Yup.string, "minValue", function (minValue) {
    return this.test(`valor`, minValue, function (value) {
      const { path, createError } = this;
      if (pedido.tipo !== 'cliente') return true

      if (!value)
        notificationCtx.error('Defina o preço e quantidade do produto');

      if (!produtoSelecionado.valor)
        notificationCtx.error('Selecione um produto');

      if ((parseFloat(value) < parseFloat(produtoSelecionado.valor)))
        notificationCtx.error('Defina um preço final maior ou igual ao preço de revenda');

      if ((parseFloat(value) > parseFloat(produtoSelecionado.valor) * 2))
        notificationCtx.error('Defina um preço final menor que 200% do preço de revenda');

      return (
        value &&
        produtoSelecionado.valor &&
        (parseFloat(value) >= parseFloat(produtoSelecionado.valor)) &&
        (parseFloat(value) < parseFloat(produtoSelecionado.valor) * 2) ||
        createError({ path, message: 'min Value' })
      );
    });
  });

  const schemaAddProduto = Yup.object().shape({
    produto: Yup.string().required(),
    quantidade: Yup.number().min(1).required(),
    valor: Yup.string().minValue(produtoSelecionado.valor)
  });

  const { register, handleSubmit, formState: { errors }, setValue, getValues, reset } = useForm({
    mode: 'all',
    resolver: yupResolver(schemaAddProduto)
  });

  const nextStep = () => {
    if (!Boolean(items.length)) {
      notificationCtx.error('Adicione ao menos 1 produto a seu pedido');
    } else {
      next()
    }
  }

  const clearProductFields = () => {
    setValorProduto(undefined)
    setProdutoSelecionado([])
    setValue('produto', '')
    setValue('quantidade', '')
    setValue('valor', '')
    reset()
  }

  const calculaTotal = (produtos) => {
    let result = produtos.reduce((acc, b) => {
      return acc + Number(b.valor)
    }, 0);
    setValorFinal(result.toFixed(2).replace('.', ','));
  }

  const setProdutoInfo = async (item) => {
    if (item.id) {
      setValorProduto(undefined)
      const response = await propostaService.produtoInfo({
        produto: item.id,
        proposta: pedido.recnum,
      });
      console.log(response.data);
      setValue('valor', '')
      setValue('produto', item.id)
      setProdutoSelecionado({ ...item, valor: parseFloat(response.data.valor).toFixed(2).replace('.', ',') })
    }
  }

  const getPedido = async () => {
    const response = await propostaService.find(pedido.id);
    setItems(response.data)
    calculaTotal(response.data)
  }

  const searchProdutos = async (text) => {
    const response = await propostaService.localProducts({
      "name": text,
      "category": categoria == 'Todas' ? '' : categoria,
      "subcategory": subCategoria == 'Todas' ? '' : subCategoria,
      "manufacturer": fabricante == 'Todos' ? '' : fabricante,
      "skipsoc": "1"
    });

    console.log(response)
    console.log({
      "name": text,
      "category": categoria == 'Todas' ? '' : categoria,
      "subcategory": subCategoria == 'Todas' ? '' : subCategoria,
      "manufacturer": fabricante == 'Todos' ? '' : fabricante,
      "skipsoc": "1"
    })
    setProdutos(response.data)
  }

  const addProduto = async () => {
    try {
      const response = await propostaService.addProduto({
        "produto": produtoSelecionado.id,
        "quantidade": quantidade,
        "valor_unitario": pedido.tipo === 'cliente' ? valorProduto : produtoSelecionado.valor,
        "valor": produtoSelecionado.valor,
        "proposta": pedido.recnum,
        "tabpreco": pedido.priceTable,
        "pagamento": pedido.pagamento,
        "plano": pedido.plano,
        "fase": pedido.fase,
        "instalacao": pedido.instalacao,
        "cliente": pedido.cliente,
        "dataCadastro": pedido.dataCadastro,
        "prevFechamento": pedido.prevFechamento,
        "dataPeriodo": pedido.dataPeriodo,
        "fim": pedido.fim,
        "duration": pedido.duration,
        "venda": produtoSelecionado.type == 'recurring' ? 0 : 1
      });

      setItems(response.data.produtos)
      calculaTotal(response.data.produtos)

      notificationCtx.success(`Produto ${produtoSelecionado.descricao.slice(0, 12) + '...'} adicionado`);
    } catch (error) {
      notificationCtx.error(`Produto já adicionado`);
    }

    clearProductFields()
  }

  const delProduto = async (id) => {
    const response = await propostaService.delProduto({
      "id_produto": id,
      "proposta": pedido.recnum,
      "tabpreco": pedido.priceTable,
      "pagamento": pedido.pagamento,
      "plano": pedido.plano,
      "fase": pedido.fase,
      "instalacao": pedido.instalacao,
      "cliente": pedido.cliente,
      "dataCadastro": pedido.dataCadastro,
      "prevFechamento": pedido.prevFechamento,
      "dataPeriodo": pedido.dataPeriodo,
      "fim": pedido.fim,
      "duration": pedido.duration
    });
    setItems(response.data.produtos)
    calculaTotal(response.data.produtos)
    notificationCtx.success(`Produto removido`);
  }

  const handleAddProduct = async () => {
    addProduto()
  };

  useDidMountEffect(() => {
    clearProductFields()
    searchProdutos('')
  }, [fabricante, categoria, subCategoria])

  useEffect(() => {
    getPedido()
  }, [])

  return (
    <>
      <form className="space-y-6">
        <div className="shadow sm:overflow-hidden sm:rounded-md">

          <div className="space-y-6 bg-white py-6 px-4 sm:p-6 ">
            <div>
              <h3 className="text-base font-semibold leading-6 text-gray-900">Buscar Produtos</h3>
              <p className="mt-1 text-sm text-gray-500">Busque o produto e adicione a quantidade desejada a seu pedido.</p>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

              <div className="sm:col-span-2">
                <Select
                  label='Fabricante'
                  options={fabricantes}
                  onChange={(selected) => setFabricante(selected.option)}
                />
              </div>

              <div className="sm:col-span-2">
                <Select
                  label='Categoria'
                  options={categorias}
                  onChange={(selected) => setCategoria(selected.option)}
                />
              </div>

              <div className="sm:col-span-2">
                <Select
                  label='Sub-Categoria'
                  options={subCategorias}
                  onChange={(selected) => setSubCategoria(selected.option)}
                />
              </div>


              <div className="sm:col-span-6">
                <input type="hidden" {...register('produto')} />
                <AutoComplete
                  label={'Produtos'}
                  placeholder={'Busque por um produto'}
                  invalid={errors.produto}
                  data={produtos}
                  fetchTerm={searchProdutos}
                  currentValue={setProdutoInfo}
                />
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="valor" className="block text-sm font-medium leading-6 text-gray-900">
                  Preço Revenda
                </label>
                <div className="mt-2 flex rounded-md shadow-sm">
                  <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                    R$
                  </span>
                  <input
                    id="revenda"
                    type="text"
                    pattern="\d*"
                    inputMode="numeric"
                    value={produtoSelecionado?.valor}
                    className='bg-gray-50 text-gray-900 shadow-sm  ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 block w-full rounded-none rounded-r-md border-0 py-1.5 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:leading-6 sm:text-sm'
                    disabled
                  />
                </div>
              </div>
              {pedido.tipo === 'cliente' &&
                <>

                  <div className="sm:col-span-2">
                    <label htmlFor="valor" className="block text-sm font-medium leading-6 text-gray-900">
                      Preço Cliente
                    </label>
                    <div className="mt-2 flex rounded-md shadow-sm">
                      <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                        R$
                      </span>
                      <input
                        id="valor"
                        {...register('valor')}
                        type="text"
                        pattern="\d*"
                        inputMode="numeric"
                        autoComplete="qtd"
                        value={valorProduto}
                        className={classNames(
                          errors.valor ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500' : 'text-gray-900 shadow-sm  ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600',
                          'block w-full rounded-none rounded-r-md border-0 py-1.5 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:leading-6 sm:text-sm'
                        )}
                        // placeholder={produtoSelecionado?.valor}
                        onChange={(e) => setValorProduto(currencyMask(e.target.value))}
                      />
                    </div>
                  </div>
                </>

              }

              <div className="sm:col-span-1">
                <label htmlFor="quantidade" className="block text-sm font-medium leading-6 text-gray-900">
                  Quantidade
                </label>
                <div className="mt-2">
                  <input
                    id="quantidade"
                    {...register('quantidade')}
                    type="number"
                    pattern="\d*"
                    inputMode="numeric"
                    min={1}
                    autoComplete="quantidade-orod"
                    value={quantidade}
                    className={classNames(
                      errors.quantidade ? 'text-red-900 ring-red-300 placeholder:text-red-300  focus:ring-red-500' : 'mt-2 px-3 text-gray-900 shadow-sm  ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600',
                      'block w-full rounded-md border-0 py-1.5 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:leading-6 sm:text-sm'
                    )}
                    onChange={(e) => setQuantidade(numberMask(e.target.value))}
                  />
                </div>
              </div>

              <div className="sm:col-span-1 flex self-end">
                <button
                  type="button"
                  className="w-[100%] inline-flex justify-center  items-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={handleSubmit(handleAddProduct)}
                >
                  {promiseInProgress ? <Spinner /> : 'Adicionar'}
                </button>
              </div>

              <div className="sm:col-span-6">
                <div className="flex items-center justify-end">




                </div>
              </div>

              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 sm:col-span-6">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          {/* <th scope="col" className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">
                            Item
                          </th> */}
                          <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                            Produto
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                            Descricao
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                            Quantidade
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                            Preço Revenda
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                            Total Revenda
                          </th>
                          {pedido.tipo === 'cliente' &&
                            <>
                              <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                Preço Cliente
                              </th>
                              <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                Total Cliente
                              </th>
                            </>
                          }
                          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {items.map((item, index) => (
                          <tr key={item.descricao + index}>

                            {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{item.item}</td> */}
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{item.produto}</td>
                            <td className="whitespace-nowrap truncate max-w-[310px] px-3 py-4 text-sm text-gray-500">{item.descricao}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{parseFloat(item.quantidade).toFixed(0)}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{item.valor_ativo.replace('.', ',')}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{(parseFloat(item.valor_ativo.replace(',', '.')) * Number(item.quantidade)).toLocaleString('pt-br', { minimumFractionDigits: 2 })}</td>
                            {pedido.tipo === 'cliente' &&
                              <>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{parseFloat(item.valor_unitario).toFixed(2).replace('.', ',')}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{item.valor.replace('.', ',')}</td>
                              </>}
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <a onClick={() => delProduto(item.recnum)} className="cursor-pointer text-red-600 hover:text-red-900">
                                <TrashIcon className="h-4 w-4 text-red-500" /><span className="sr-only">, {item.name}</span>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="sm:col-span-6">
                <div className="flex items-center justify-between pt-2">
                  <dt className="text-base pl-1 text-sm font-medium">Total</dt>
                  <dd className="text-base pr-1 text-sm font-medium text-gray-900">R$ {valorFinal}</dd>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className="py-2 text-end ">
          <button
            type="button"
            className="w-[250px] border border-indigo-200 rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
            //className="w-[150px] inline-flex justify-center items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={nextStep}
          >
            Avançar
          </button>
        </div>
      </form>
    </>
  );
}
