import { useState, useEffect } from 'react'
import { currencyMask } from 'utils/masks';
import QuoteBox from 'components/QuoteBox';
import DevicesAccordion from 'components/DevicesAccordion';
import useDidMountEffect from 'hooks/use-did-mount-effect';

let devices = [
  {
    "id": 4,
    "nome": "Next Generation Firewall - P",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "gb_fixed": 0.5,
    "min_gb": 0.5,
    "epsValue": 50,
    "raw": 0,
    "category": "Segurança",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 2,
    "nome": "Next Generation Firewall - M",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "gb_fixed": 1,
    "min_gb": 1,
    "epsValue": 100,
    "raw": 0,
    "category": "Segurança",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 3,
    "nome": "Next Generation Firewall - G",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "gb_fixed": 2,
    "min_gb": 2,
    "epsValue": 240,
    "raw": 0,
    "category": "Segurança",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 1,
    "nome": "Next Generation Firewall - GG",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "gb_fixed": 3,
    "min_gb": 3,
    "epsValue": 240,
    "raw": 0,
    "category": "Segurança",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 5,
    "nome": "Balanceadores de carga de rede",
    "eps": 0,
    "gb": 0,
    "gb_fixed": 1,
    "min_gb": 1,
    "epd": 0,
    "epsValue": 5,
    "raw": 0,
    "category": "Infraestrutura",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 6,
    "nome": "Controladora WIFI",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "gb_fixed": 0.1,
    "min_gb": 0.1,
    "epsValue": 5,
    "raw": 0,
    "category": "Redes",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 9,
    "nome": "Servidor Windows (Alto/Médio EPS) (ex. Proxy, Exchange...)",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "gb_fixed": 1,
    "min_gb": 1,
    "epsValue": 3,
    "raw": 0,
    "category": "Infraestrutura",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 10,
    "nome": "Servidor Windows (Baixo EPS) (WEB, fileserver, AD...)",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "gb_fixed": 0.2,
    "min_gb": 0.2,
    "epsValue": 1,
    "raw": 0,
    "category": "Infraestrutura",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 11,
    "nome": "Desktops Windows",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "gb_fixed": 0.01,
    "min_gb": 0.01,
    "epsValue": 0.01,
    "raw": 0,
    "category": "Endpoints",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 13,
    "nome": "Roteadores",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "gb_fixed": 0.1,
    "min_gb": 0.1,
    "epsValue": 1,
    "raw": 0,
    "category": "Redes",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 14,
    "nome": "Switch Core / L3",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "gb_fixed": 0.5,
    "min_gb": 0.5,
    "epsValue": 2,
    "raw": 0,
    "category": "Redes",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 15,
    "nome": "Switch Acesso / L2",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "gb_fixed": 0.1,
    "min_gb": 0.1,
    "epsValue": 5,
    "raw": 0,
    "category": "Redes",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 17,
    "nome": "SD-WAN appliance",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "gb_fixed": 0.5,
    "min_gb": 0.5,
    "epsValue": 2,
    "raw": 0,
    "category": "Redes",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 19,
    "nome": "HyperVisor (ESXi, Hyper-V etc)",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 15,
    "gb_fixed": 0.2,
    "min_gb": 0.2,
    "raw": 0,
    "category": "Infraestrutura",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 20,
    "nome": "Outros Dispositivos de Segurança",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "gb_fixed": 0.5,
    "min_gb": 0.5,
    "epsValue": 10,
    "raw": 0,
    "category": "Segurança",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 21,
    "nome": "Estação de Trabalho Windows",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "gb_fixed": 0.2,
    "min_gb": 0.2,
    "epsValue": 1,
    "raw": 0,
    "category": "Endpoints",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 23,
    "nome": "Servidores Linux / Unix (Alto/Médio EPS)",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "gb_fixed": 1,
    "min_gb": 1,
    "epsValue": 2,
    "raw": 0,
    "category": "Infraestrutura",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 24,
    "nome": "Servidores Linux / Unix (Baixo EPS)",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "gb_fixed": 0.2,
    "min_gb": 0.2,
    "epsValue": 2,
    "raw": 0,
    "category": "Infraestrutura",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 33,
    "nome": "Antispam de rede",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "gb_fixed": 1,
    "min_gb": 1,
    "epsValue": 10,
    "raw": 0,
    "category": "Segurança",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 37,
    "nome": "Servidor antivírus (Mcafee / Kaspersky / Bitdefender)",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "gb_fixed": 0.5,
    "min_gb": 0.5,
    "epsValue": 5,
    "raw": 0,
    "category": "Segurança",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 38,
    "nome": "Microsoft 365 (por usuário)",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "gb_fixed": 0.0025,
    "min_gb": 0.25,
    "epsValue": 5,
    "raw": 0,
    "category": "SaaS (Microsoft / Google)",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 39,
    "nome": "Google Workspace (por usuário)",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "gb_fixed": 0.0025,
    "min_gb": 0.25,
    "epsValue": 5,
    "raw": 0,
    "category": "SaaS (Microsoft / Google)",
    "estimated": 0,
    "quantity": 0
  }
]

const categories = [
  { category: "Infraestrutura" },
  { category: "Segurança" },
  { category: "Redes" },
  { category: "Endpoints" },
  { category: "SaaS (Microsoft / Google)" },
]

export default function SoCQuote({ setGBs, setSelectedDevices, precoRevenda }) {
  const [devicesList, setDevicesList] = useState(devices)
  const [totalEstimated, setTotalEstimated] = useState({ gb: 0, cost: 0, quantity: 0 })

  const setDeviceInfo = (id, quantity) => {
    const newState = devicesList.map((device) => {
      if (device.id === id) {
        let eps = (quantity * device.epsValue).toFixed(2)

        let gb = (device.gb_fixed * quantity).toFixed(2)

        if (gb < device.min_gb && quantity > 0) gb = device.min_gb

        // 2500 eh o custo do gb (by Marco Ierizzo)
        let estimated = gb * 2500

        return {
          ...device,
          eps,
          gb,
          estimated,
          quantity
        }
      }

      return device
    })

    setSelectedDevices(newState.filter((device) => device.quantity > 0))
    setDevicesList(newState)
  }

  useEffect(() => {
    setTotalEstimated(devicesList.reduce(({ gb, cost, quantity }, device) => {
      return {
        gb: gb + Number(device.gb),
        cost: cost + Number(device.estimated),
        quantity: (device.id !== 11 && device.id !== 21) ? quantity + Number(device.quantity) : quantity,
      }
    }, { gb: 0, cost: 0, quantity: 0 }))
  }, [devicesList]);

  useDidMountEffect(() => {
    const gbValue = totalEstimated.gb === 0 ? 0 : Math.max(1, totalEstimated.gb);  // Keeps 0 if it's 0, otherwise ensures at least 1
    setGBs(Math.round(gbValue));
    precoRevenda(currencyMask((Math.round(gbValue) * 2500).toFixed(2)));
  }, [totalEstimated]);

  return (
    <div className="mx-auto max-w-6xl">
      <h2 className="text-lg font-bold text-gray-900">SoC As a Services Full - Calculadora</h2>
      <p className="mt-6 text-sm leading-8 text-gray-600">
        Obtenha uma estimativa da quantidade diária de dados ingeridos da sua infraestrutura na sua plataforma SIEM + SOAR e UEBA. Os resultados podem ser exportados como um PDF para seu próprio uso ou para obter uma cotação.
      </p>

      <div className='grid grid-cols-12 gap-9 mt-6'>
        <div className="col-span-9">
          <DevicesAccordion
            devices={devicesList}
            categories={categories}
            setDeviceInfo={setDeviceInfo}
          />
        </div>
        <QuoteBox
          gb={totalEstimated.gb === 0 ? 0 : Math.round(Math.max(1, totalEstimated.gb))}
          cost={currencyMask(
            (totalEstimated.gb === 0 ? 0 : Math.round(Math.max(1, totalEstimated.gb)) * 2500).toFixed(2)
          )}
        />


      </div>

    </div>
  )
}
