import { useState, useEffect } from 'react'
import { currencyMask } from 'utils/masks';
import QuoteBox from 'components/QuoteBox';
import DevicesAccordion from 'components/DevicesAccordion';

let devices = [
  {
    "id": 4,
    "nome": "Next Generation Firewall - P",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 50,
    "raw": 0,
    "category": "Segurança",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 2,
    "nome": "Next Generation Firewall - M",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 100,
    "raw": 0,
    "category": "Segurança",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 3,
    "nome": "Next Generation Firewall - G",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 240,
    "raw": 0,
    "category": "Segurança",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 1,
    "nome": "Next Generation Firewall - GG",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 240,
    "raw": 0,
    "category": "Segurança",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 5,
    "nome": "Balanceadores de carga de rede",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 5,
    "raw": 0,
    "category": "Infraestrutura",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 6,
    "nome": "Rede LAN sem fio",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 5,
    "raw": 0,
    "category": "Redes",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 7,
    "nome": "Fluxos de rede (NetFlow / S-Flow)",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 30,
    "raw": 0,
    "category": "Redes",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 8,
    "nome": "Servidor Windows (Alto EPS)",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 50,
    "raw": 0,
    "category": "Infraestrutura",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 9,
    "nome": "Servidor Windows (Medio EPS)",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 3,
    "raw": 0,
    "category": "Infraestrutura",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 10,
    "nome": "Servidor Windows (Baixo EPS)",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 1,
    "raw": 0,
    "category": "Infraestrutura",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 11,
    "nome": "Desktops Windows",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 0.01,
    "raw": 0,
    "category": "Endpoints",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 12,
    "nome": "Servidores Linux / Unix",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 1,
    "raw": 0,
    "category": "Infraestrutura",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 13,
    "nome": "Roteadores",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 1,
    "raw": 0,
    "category": "Redes",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 14,
    "nome": "Switches",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 2,
    "raw": 0,
    "category": "Redes",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 15,
    "nome": "Outros Dispositivos de Rede",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 5,
    "raw": 0,
    "category": "Redes",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 16,
    "nome": "Rede IPS / IDS",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 15,
    "raw": 0,
    "category": "Segurança",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 17,
    "nome": "VPN de rede / VPN SSL",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 2,
    "raw": 0,
    "category": "Redes",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 18,
    "nome": "Redes Web Proxy",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 15,
    "raw": 0,
    "category": "Redes",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 19,
    "nome": "HyperVisor (ESXi, Hyper-V etc)",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 15,
    "raw": 0,
    "category": "Infraestrutura",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 20,
    "nome": "Outros Dispositivos de Segurança",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 10,
    "raw": 0,
    "category": "Segurança",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 21,
    "nome": "Estação de Trabalho Windows",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 1,
    "raw": 0,
    "category": "Endpoints",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 22,
    "nome": "Servidores Windows AD",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 10,
    "raw": 0,
    "category": "Infraestrutura",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 23,
    "nome": "Servidores IBM AIX Unix\t",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 2,
    "raw": 0,
    "category": "Infraestrutura",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 24,
    "nome": "Servidores HP-UX Unix",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 2,
    "raw": 0,
    "category": "Infraestrutura",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 25,
    "nome": "Servidores Sun Solaris Unix",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 2,
    "raw": 0,
    "category": "Infraestrutura",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 26,
    "nome": "IBM Mainframe / Midrange",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 2,
    "raw": 0,
    "category": "Infraestrutura",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 27,
    "nome": "Switches (Netflow)",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 30,
    "raw": 0,
    "category": "Redes",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 28,
    "nome": "WAL Accelerator",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 14,
    "raw": 0,
    "category": "Infraestrutura",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 29,
    "nome": "Firewalls de rede (Check Point - interno)",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 10,
    "raw": 0,
    "category": "Segurança",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 30,
    "nome": "Firewalls de rede (Check Point - DMZ)",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 50,
    "raw": 0,
    "category": "Segurança",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 31,
    "nome": "Firewalls de rede (Cisco - interno)",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 10,
    "raw": 0,
    "category": "Segurança",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 32,
    "nome": "Firewalls de rede (Cisco - DMZ)",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 30,
    "raw": 0,
    "category": "Segurança",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 33,
    "nome": "Antispam de rede\t",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 10,
    "raw": 0,
    "category": "Segurança",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 34,
    "nome": "Servidores da Web (IIS, Tomcat, Apache)",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 1,
    "raw": 0,
    "category": "Infraestrutura",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 35,
    "nome": "Banco de dados (MSSQL, Oracle, Sybase - número de instâncias)",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 1,
    "raw": 0,
    "category": "Infraestrutura",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 36,
    "nome": "Servidores de e-mail (Exchange, Sandmail, etc)",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 2,
    "raw": 0,
    "category": "Infraestrutura",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 37,
    "nome": "Servidor antivírus (Mcafee / Kaspersky / Bitdefender)",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 5,
    "raw": 0,
    "category": "Segurança",
    "estimated": 0,
    "quantity": 0
  },
  {
    "id": 38,
    "nome": "Outros aplicativos (e-mail, banco de dados, AV, etc)",
    "eps": 0,
    "gb": 0,
    "epd": 0,
    "epsValue": 5,
    "raw": 0,
    "category": "Infraestrutura",
    "estimated": 0,
    "quantity": 0
  }
]

function logSizeDay(DEVICE_EPS_TOTAL) {
  let e = 0;
  return e = 86400 * DEVICE_EPS_TOTAL * 1500,
    e *= 9.313 * Math.pow(10, -10),
    e = Number(Math.round(e).toFixed(2)),
    e
}

const categories = [
  { category: "Infraestrutura" },
  { category: "Segurança" },
  { category: "Redes" },
  { category: "Endpoints" },
]

export default function SocAnalyticsReportsQuote({ setGBs, setSelectedDevices, precoRevenda }) {
  const [isShowing, setIsShowing] = useState(false)
  const [devicesList, setDevicesList] = useState(devices)
  const [totalEstimated, setTotalEstimated] = useState({ gb: 0, cost: 0, quantity: 0 })

  function manHoursValue() {
    return ((133.33 * 3) * 1.42)
  }

  const setDeviceInfo = (id, quantity) => {
    let preco_custo = 1.08
    let markup = 1.852

    let preco_venda = (preco_custo * markup)

    let dias = 30
    let fator_paloni = 50

    let credito_per_giga = 25

    const newState = devicesList.map((device) => {
      if (device.id == id) {
        let eps = quantity * device.epsValue
        let gb = logSizeDay(eps)
        let estimated = ((((gb * dias) / fator_paloni) * credito_per_giga) * 0.7) * preco_venda

        return {
          ...device,
          eps,
          gb,
          estimated,
          quantity
        }
      }

      return device
    })
    setSelectedDevices(newState.filter((device) => device.quantity > 0))
    setDevicesList(newState)
  }

  useEffect(() => {
    setTotalEstimated(devicesList.reduce(({ gb, cost, quantity }, device) => {
      return {
        gb: gb + Number(device.gb),
        cost: cost + Number(device.estimated),
        quantity: (device.id !== 11 && device.id !== 21) ? quantity + Number(device.quantity) : quantity,
      }
    }, { gb: 0, cost: 0, quantity: 0 }))
  }, [devicesList]);

  useEffect(() => {
    precoRevenda(totalEstimated.quantity ? currencyMask((manHoursValue() + totalEstimated.cost).toFixed(2)) : currencyMask((totalEstimated.cost).toFixed(2)))
    setGBs(totalEstimated.gb)
  }, [totalEstimated]);

  useEffect(() => {
    setTimeout(() => setIsShowing(true), 100);
  }, []);

  return (
    <div className="mx-auto max-w-6xl">
      <h2 className="text-lg font-bold text-gray-900">SoC As a Services Analytics & Reports - Calculadora</h2>
      <p className="mt-6 text-sm leading-8 text-gray-600">
        Get an estimate on the daily amount of data ingested from your infrastructure into your SIEM platform. The results can be exported as a PDF for your own use, or to get a quote.
      </p>

      <div className='grid grid-cols-12 gap-9 mt-6'>
        <div className="col-span-9">
          <DevicesAccordion
            devices={devicesList}
            categories={categories}
            setDeviceInfo={setDeviceInfo}
          />
        </div>
        <QuoteBox
          gb={totalEstimated.gb}
          cost={totalEstimated.quantity ? currencyMask((manHoursValue() + totalEstimated.cost).toFixed(2)) : currencyMask((totalEstimated.cost).toFixed(2))}
        />
      </div>

    </div>
  )
}
