import { Transition, Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
import { numberMask } from 'utils/masks';

export default function DevicesAccordion({ categories, devices, setDeviceInfo, defaultOpen }) {
  return (
    <dl className="space-y-6 divide-y divide-gray-900/10">
      {categories.map((c) => (
        <Disclosure as="div" defaultOpen={defaultOpen} key={c.category} className="pt-6">
          {({ open }) => (
            <>
              <dt>
                <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                  <span className="text-base font-semibold leading-7">{c.category}</span>
                  <span className="ml-6 flex h-7 items-center">
                    {open ? (
                      <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                    ) : (
                      <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                    )}
                  </span>
                </Disclosure.Button>
              </dt>
              <Transition
                enter="transition ease-in-out duration-[500ms] transform"
                enterFrom="-translate-y-2 opacity-10"
                enterTo="translate-y-0 opacity-100"
                leave="transition ease-in-out duration-[250ms] transform"
                leaveFrom="translate-y-0 opacity-100"
                leaveTo="-translate-y-2 opacity-0"
              >
                <Disclosure.Panel as="dd" className="mt-8">
                  <div className="grid grid-cols-8 gap-5 bg-gray-50 py-2 text-sm font-semibold text-gray-700 leading-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <div className="col-span-4 sm:col-span-4 ">
                      <p className="pl-2">Device Type</p>
                    </div>
                    <div className="col-span-2 sm:col-span-2">
                      Quantity
                    </div>
                    <div className="col-span-1 sm:col-span-1">
                      EPS
                    </div>
                    <div className="col-span-1 sm:col-span-1">
                      GB/day
                    </div>
                  </div>
                  {devices.map((device) => {
                    if (device.category === c.category) {
                      return <div key={device.id} className="grid grid-cols-8 gap-6 mt-10 items-center text-sm leading-8 text-black-800">
                        <div className="pl-2 col-span-4 sm:col-span-4">
                          {device.nome}
                        </div>
                        <div className="col-span-1 sm:col-span-2">
                          <div className="rounded-md shadow-sm">
                            <input
                              type="number"
                              id="quantity"
                              onChange={(e) => setDeviceInfo(device.id, numberMask(e.target.value))}
                              max={500}
                              min={0}
                              value={device.quantity}
                              autoComplete="quantity"
                              className="w-[100%] rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                        <div className="col-span-1 sm:col-span-1">
                          {device.eps}
                        </div>
                        <div className="col-span-1 sm:col-span-1">
                          {device.gb}
                        </div>
                      </div>
                    }
                  })}

                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      ))}
    </dl>
  );
}
