import { useState, useEffect } from 'react'
import { Transition } from '@headlessui/react'
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import Select from 'components/Select';
import { NavLink } from 'react-router-dom';
import propostaService from 'services/proposta.service';
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid'
import { format } from 'date-fns'
import DatePicker from 'components/DatePicker';

const statuses = [
  { id: 0, option: 'Todos' },
  { id: 1, option: 'Aprovado' },
  { id: 2, option: 'Aguardando Aprovação' },
  { id: 3, option: 'Perdida' }
]

// const tipos = [
//   { id: '-1', option: 'Todos' },
//   { id: '1', option: 'Plano de Locação 12 Meses' },
//   { id: '2', option: 'Plano de Locação 24 Meses' },
//   { id: '3', option: 'Plano de Locação 36 Meses' },
// ]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ConsultaXaaS() {
  const [isShowing, setIsShowing] = useState(false)
  const [pedidos, setPedidos] = useState([])

  const [inicio, setInicio] = useState('01/01/2022')
  const [fim, setFim] = useState(format(new Date(), 'dd/MM/yyyy'))
  const [tipo, setTipo] = useState('-1')
  const [status, setStatus] = useState(0)

  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage,] = useState(8)
  const [pages, setPages] = useState(1)

  const listarPedidos = async () => {
    const response = await propostaService.listar({ tipo, status, inicio, fim });
    setPedidos(response.data.reverse())
    setPages(Math.ceil(response.data.length / rowsPerPage));
    setCurrentPage(1)
  }

  const excluirPedido = async (code) => {
    await propostaService.delProposta({ code });
    listarPedidos()
  }

  useEffect(() => {
    listarPedidos()
    setTimeout(() => setIsShowing(true), 100);
  }, []);

  return (
    <Transition
      show={isShowing}
      enter="transition ease-in-out duration-[1000ms] transform"
      enterFrom="-translate-y-2 opacity-0"
      enterTo="translate-y-0 opacity-100"
      leave="transition ease-in-out duration-[1000ms] transform"
      leaveFrom="translate-y-0 opacity-100"
      leaveTo="-translate-y-2 opacity-0"
    >
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="pt-10 pb-16">
          <div className="px-4 sm:px-6 lg:px-0">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">Consultar Pedidos</h1>
          </div>
          <div className="py-6">
            <div className="shadow sm:overflow-hidden sm:rounded-md">

              <div className="space-y-6 bg-white py-6 px-4 sm:p-6 min-h-[550px]">
                <div>
                  <h3 className="text-base font-semibold leading-6 text-gray-900">Filtrar pedidos</h3>
                  <p className="mt-1 text-sm text-gray-500">Consulte os pedidos e cotações vinculados ao seu cadastro.</p>
                </div>
                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-1">
                    <DatePicker
                      label={'Data do Pedido'}
                      onChange={(e) => setInicio(format(e, 'dd/MM/yyyy'))}
                    />
                  </div>
                  <div className="sm:col-span-1">
                    <DatePicker
                      label={'Até'}
                      onChange={(e) => setFim(format(e, 'dd/MM/yyyy'))}
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <Select
                      label='Status'
                      options={statuses}
                      onChange={(selected) => setStatus(selected.id)}
                    />
                  </div>
                  {/* <div className="sm:col-span-2">
                    <Select
                      label='Tipo'
                      options={tipos}
                      onChange={(selected) => setTipo(selected.id)}
                    />
                  </div> */}
                  <div className="flex items-center justify-between">
                    <button
                      type="button"
                      className="mt-6 w-[200px] inline-flex justify-center  items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={listarPedidos}
                    >
                      Filtrar
                    </button>
                  </div>
                  <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 sm:col-span-6">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr>
                              <th scope="col" className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">
                                #
                              </th>
                              <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                Status
                              </th>
                              <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                Cliente
                              </th>
                              {/* <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                Data Início
                              </th>
                              <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                Data Fim
                              </th>
                              <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                Tipo
                              </th> */}
                              <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                Valor
                              </th>
                              <th scope="col" className="relative py-3.5 pl-3">
                                <span className="sr-only">Editar</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {pedidos.slice((currentPage - 1) * rowsPerPage, (currentPage * rowsPerPage)).map((item, index) => {
                              console.log(item);
                              return (
                                <tr key={item.codigo}>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{item.codigo}</td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{item.statusProposta ? item.statusProposta : 'Aguardando Aprovação'}</td>
                                  <td className="whitespace-nowrap truncate max-w-[200px] px-3 py-4 text-center text-sm text-gray-500">{item.nomefantasia}</td>
                                  {/* <td className="whitespace-nowrap truncate max-w-[200px] px-3 py-4 text-center text-sm text-gray-500">{item.data_inicio}</td>
                                  <td className="whitespace-nowrap truncate max-w-[200px] px-3 py-4 text-center text-sm text-gray-500">{item.data_fim}</td>
                                  <td className="whitespace-nowrap truncate max-w-[200px] px-3 py-4 text-sm text-gray-500">{item.tipo_descricao}</td> */}
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{item.valor_total}</td>
                                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium flex gap-2 justify-center">
                                    {item.statusProposta !== 'APROVADO' && <>
                                      <TrashIcon onClick={() => excluirPedido(item.codigo)} className="cursor-pointer h-4 w-4 text-red-500" /><span className="sr-only"></span>
                                    </>}
                                    <NavLink to={`/xaas/consultar/${item.codigo}`} className="cursor-pointer text-red-600 hover:text-red-900">
                                      <PencilSquareIcon className="h-4 w-4 text-yellow-500" /><span className="sr-only"></span>
                                    </NavLink>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-6 px-[5px]">
                    <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">

                      <div className="-mt-px flex w-0 flex-1">
                        <button
                          disabled={currentPage === 1}
                          onClick={() => setCurrentPage((currentPage - 1))}
                          className="cursor-pointer  inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                        >
                          <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                          Anterior
                        </button>
                      </div>
                      <div className="hidden md:-mt-px md:flex">
                        {[...Array(Math.ceil(pedidos.length / rowsPerPage))].map((x, i) =>
                          <a
                            key={i}
                            onClick={() => setCurrentPage((i + 1))}
                            className={classNames(
                              (i + 1 === currentPage) ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                              'cursor-pointer inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium'
                            )}
                          >
                            {i + 1}
                          </a>
                        )}
                      </div>
                      <div className="-mt-px flex w-0 flex-1 justify-end">
                        <button
                          disabled={currentPage === Math.ceil(pedidos.length / rowsPerPage)}
                          onClick={() => setCurrentPage((currentPage + 1))}
                          className="cursor-pointer inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                        >
                          Próximo
                          <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                        </button>
                      </div>
                    </nav>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition >
  )
}
