import { useState, useEffect, useRef } from 'react'
import { CheckIcon } from '@heroicons/react/24/solid'
import { Transition } from '@headlessui/react'
import Step1 from 'components/locacao/criar/etapas/dados-gerais'
import Step2 from 'components/locacao/criar/etapas/adicionar-produtos'
import Step3 from 'components/locacao/criar/etapas/resumo'

export default function Locacao() {
  const [steps, setSteps] = useState([
    { id: '01', name: 'Dados Gerais', status: 'current', border: 'border-indigo-600', text: 'text-indigo-600' },
    { id: '02', name: 'Adicionar Produtos', status: 'upcoming', border: 'border-gray-300', text: 'text-gray-900' },
    { id: '03', name: 'Resumo', status: 'upcoming', border: 'border-gray-300', text: 'text-gray-900' },
  ])
  const [isShowing, setIsShowing] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [pedido, setPedido] = useState()

  const nextScene = () => {
    for (let [index, step] of steps.entries()) {
      if (step.status === 'current' && (index + 1) < steps.length) {
        step.status = 'complete'

        steps[index + 1].status = 'current'
        steps[index + 1].border = 'border-indigo-600'
        steps[index + 1].text = 'text-indigo-600'

        setCurrentStep(index + 1)
        window.scrollTo(0, 0)
        break;
      }
    }
  }

  const prevScene = () => {
    for (let [index, step] of steps.entries()) {
      if (step.status === 'current' && (index - 1) >= 0) {
        step.status = 'upcoming'

        steps[index - 1].status = 'current'
        steps[index - 1].border = 'border-indigo-600'
        steps[index - 1].text = 'text-indigo-600'

        setCurrentStep(index - 1)
        window.scrollTo(0, 0)
        break;
      }
    }
  }
  
  const renderScene = (current) => {
    switch (current) {
      case 1:
        return <Step2 next={nextScene} prev={prevScene} pedido={pedido} />
  
      case 2:
        return <Step3 prev={prevScene} pedido={pedido} />
  
      default:
        return <Step1 next={nextScene} callback={setPedido} />
    }
  }

  useEffect(() => {
    setTimeout(() => setIsShowing(true), 100);
  }, []);

  return (
    <Transition
      show={isShowing}
      enter="transition ease-in-out duration-[1000ms] transform"
      enterFrom="-translate-y-2 opacity-0"
      enterTo="translate-y-0 opacity-100"
      leave="transition ease-in-out duration-[1000ms] transform"
      leaveFrom="translate-y-0 opacity-100"
      leaveTo="-translate-y-2 opacity-0"
    >
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="pt-10 pb-16">
          <div className="px-4 sm:px-6 lg:px-0">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">Criar Cotação</h1>
          </div>
          <div className="py-6">
            <nav aria-label="Progress">
              <ul role="list" className="divide-y divide-gray-300 rounded-md shadow md:flex md:divide-y-0 bg-white">
                {steps.map((step, stepIdx) => (
                  <li key={step.name} className="relative md:flex md:flex-1">

                    {step.status === 'complete' ? (
                      <div className="transition-colors duration-[1s] group flex w-full items-center">
                        <span className="flex items-center px-6 py-4 text-sm font-medium">
                          <span className="transition-all duration-[500ms] flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                            <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                          </span>
                          <span className="ml-4 text-sm font-medium text-gray-900">{step.name}</span>
                        </span>
                      </div>
                    ) : step.status === 'current' ? (

                      <div className="flex items-center px-6 py-4 text-sm font-medium " aria-current="step">
                        <span className={`flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 transition-colors duration-[1s] ${step.text} ${step.border}`}>
                          <span className={`transition-colors duration-[1s] text-gray-300 ${step.text}`}>{step.id}</span>
                        </span>
                        <span className={`transition-all duration-[4s] ml-4 text-sm font-medium ${step.text}`}>{step.name}</span>
                      </div>

                    ) : (
                      <div className="group flex items-center">
                        <span className="transition-colors duration-[1s] flex items-center px-6 py-4 text-sm font-medium">
                          <span className="transition-colors duration-[1s] flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                            <span className="transition-colors duration-[1s] text-gray-500">{step.id}</span>
                          </span>
                          <span className="transition-all duration-[4s] ml-4 text-sm font-medium text-gray-500">{step.name}</span>
                        </span>
                      </div>
                    )}
                    {stepIdx !== steps.length - 1 ? (
                      <>
                        <div className="absolute top-0 right-0 hidden h-full w-5 md:block" aria-hidden="true">
                          <svg
                            className="h-full w-full text-gray-300"
                            viewBox="0 0 22 80"
                            fill="none"
                            preserveAspectRatio="none"
                          >
                            <path
                              d="M0 -2L20 40L0 82"
                              vectorEffect="non-scaling-stroke"
                              stroke="currentcolor"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </>
                    ) : null}
                  </li>
                ))}
              </ul>
            </nav>
          </div>
          
          { renderScene(currentStep) }

        </div>
      </div>
    </Transition >
  )
}
