import { useState, useEffect, useContext } from 'react';
import { Transition } from '@headlessui/react';
import Selector from 'components/Select';
import { cnpjMask, cepMask, phoneMask } from 'utils/masks';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import clienteService from 'services/cliente.service';
import Spinner from 'components/Spinner';
import NotificationContext from 'context/notification';

const grupo = [
  { id: 1, option: 'Não Pertence' },
  { id: 2, option: 'Revendedor' },
  { id: 3, option: 'Cliente Final' },
  { id: 4, option: 'Instituição Financeira' },
  { id: 5, option: 'Fornecedor' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Clientes() {
  const notificationCtx = useContext(NotificationContext);  

  const [isShowing, setIsShowing] = useState(false)
  const [, setGrupoClientes] = useState(false)

  const [cnpj, setCNPJ] = useState('')
  const [telefone, setTelefone] = useState('')
  const [celular, setCelular] = useState('')

  const schema = Yup.object().shape({
    cnpj: Yup.string().required(),
    inscricaoEstadual: Yup.string().matches('^(ISENTO|\\d{12})$').required(),
    razaoSocial: Yup.string().required(),
    cep: Yup.string().required(),
    endereco: Yup.string().required(),
    numero: Yup.string().required(),
    bairro: Yup.string().required(),
    municipio: Yup.string().required(),
    uf: Yup.string().required(),
    telefone: Yup.string().required()
  });

  const { register, handleSubmit, formState: { errors }, setValue } = useForm({
    mode: 'all',
    resolver: yupResolver(schema)
  });

  const handleValidSubmit = async (data) => {
    try {
      const result = await clienteService.register(data);
      if (!result.data.erro){
        notificationCtx.success('Cliente cadastrado!');
      }else{
        notificationCtx.error('Erro! Algo deu errado ao cadastrar o cliente');
      }
    } catch (error) {
      notificationCtx.error(error);
    }
  };

  const getCNPJ = async (cnpj) => {
    if (cnpj.length === 18) {
      try {
        const result = await clienteService.getCNPJ({ cnpj });
        if (!result.data.erro) {
          
          setValue('bairro', result.data.bairro)
          setValue('cep', result.data.cep)
          setValue('complemento', result.data.complemento)
          setValue('fantasia', result.data.fantasia)
          setValue('endereco', result.data.logradouro)
          setValue('municipio', result.data.municipio.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase())
          setValue('razaoSocial', result.data.nome)
          setValue('numero', result.data.numero)
          setValue('telefone', result.data.telefone)
          setTelefone(result.data.telefone)
          setValue('uf', result.data.uf)
          setValue('cnpj', cnpj)
          notificationCtx.success('CNPJ encontrado!');
        } else {
          notificationCtx.error(result.data.validacao);
        }
      } catch (error) {
        notificationCtx.error(error);
      }
    }
  }

  useEffect(() => {
    setTimeout(() => setIsShowing(true), 100);
  }, []);

  return (
    <Transition
      show={isShowing}
      enter="transition ease-in-out duration-[1000ms] transform"
      enterFrom="-translate-y-2 opacity-0"
      enterTo="translate-y-0 opacity-100"
      leave="transition ease-in-out duration-[1000ms] transform"
      leaveFrom="translate-y-0 opacity-100"
      leaveTo="-translate-y-2 opacity-0"
    >
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
        <div className="pt-4 sm:pt-10 pb-16">
          <div className="px-4 sm:px-6 lg:px-0">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">Cadastrar Cliente</h1>
          </div>
          <div className="py-6">
            <form className="mt-8 space-y-6" onSubmit={handleSubmit(handleValidSubmit)}>
              <div className="shadow sm:overflow-hidden sm:rounded-md">
                <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                  <div>
                    <h3 className="text-base font-semibold leading-6 text-gray-900">Informações do Cliente</h3>
                    <p className="mt-1 text-sm text-gray-500">Preencha o CNPJ e complete as informações restantes de identificação do cliente.</p>
                  </div>

                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-1">
                      <label htmlFor="cnpj" className="block text-sm font-medium leading-6 text-gray-900">
                        CNPJ
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <input
                          type="text"
                          inputmode="numeric"
                          {...register('cnpj')}
                          id="cnpj"
                          value={cnpj}
                          onBlur={(e) => getCNPJ(cnpjMask(e.target.value))}
                          onChange={(e) => setCNPJ(cnpjMask(e.target.value))}
                          maxLength={18}
                          autoComplete="cnpj"
                          className={classNames(
                            errors.cnpj ? 'pr-10 text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500' : 'mt-2 px-3 text-gray-900 shadow-sm  ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600',
                            'block w-full rounded-md border-0 py-1.5 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:leading-6 sm:text-sm'
                          )}
                        />
                        {errors.cnpj && <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                        </div>}
                      </div>
                    </div>

                    <div className="col-span-6 sm:col-span-2">
                      <label htmlFor="inscricaoEstadual" className="block text-sm font-medium leading-6 text-gray-900">
                        Inscrição Estadual
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <input
                          type="text"
                          {...register('inscricaoEstadual')}
                          id="inscricaoEstadual"
                          autoComplete="inscricao-estadual"
                          onChange={(e) => setValue('inscricaoEstadual', e.target.value.toUpperCase())}
                          className={classNames(
                            errors.inscricaoEstadual ? 'pr-10 text-red-900 ring-red-300 placeholder:text-red-300  focus:ring-red-500' : 'mt-2 px-3 text-gray-900 shadow-sm  ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600',
                            'block w-full rounded-md border-0 py-1.5 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:leading-6 sm:text-sm'
                          )}
                        />
                        {errors.inscricaoEstadual && <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                        </div>}
                      </div>
                    </div>

                    <div className="col-span-6 sm:col-span-2">
                      <label htmlFor="inscricaoMunicipal" className="block text-sm font-medium leading-6 text-gray-900">
                        Inscrição Municipal
                      </label>
                      <input
                        type="text"
                        name='inscricaoMunicipal'
                        id="inscricaoMunicipal"
                        defaultValue={''}
                        onChange={(e) => setValue('inscricaoMunicipal', e.target.value)}
                        autoComplete="inscricao-municipal"
                        className="mt-2 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-1 mt-[4px]">
                      <Selector
                        label='Grupo'
                        options={grupo}
                        onChange={(e) => setGrupoClientes(e.id)}
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="razaoSocial" className="block text-sm font-medium leading-6 text-gray-900">
                        Razão Social
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <input
                          type="text"
                          {...register('razaoSocial')}
                          id="razaoSocial"
                          autoComplete="razao-social"
                          onChange={(e) => setValue('razaoSocial', e.target.value)}
                          disabled={true}
                          className={classNames(
                            errors.razaoSocial ? 'pr-10 text-red-900 ring-red-300 placeholder:text-red-300  focus:ring-red-500' : 'mt-2 px-3 text-gray-900 shadow-sm  ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600',
                            'block w-full rounded-md border-0 py-1.5 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:leading-6 sm:text-sm'
                          )}
                        />
                        {errors.razaoSocial && <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                        </div>}
                      </div>
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="nomeFantasia" className="block text-sm font-medium leading-6 text-gray-900">
                        Nome Fantasia
                      </label>
                      <input
                        type="text"
                        {...register('fantasia')}
                        id="fantasia"
                        onChange={(e) => setValue('fantasia', e.target.value)}
                        autoComplete="fantasia"
                        className="mt-2 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>

                <div className="space-y-6 bg-white py-6 px-4 sm:p-6 ">
                  <div style={{ borderTop: '1px solid #efefef' }}></div>
                  <div>
                    <h3 className="text-base font-semibold leading-6 text-gray-900">Endereço Principal</h3>
                    <p className="mt-1 text-sm text-gray-500">Preencha as informações de endereço do cliente.</p>
                  </div>

                  <div className="grid grid-cols-6 gap-6">

                    <div className="col-span-6 sm:col-span-1">
                      <label htmlFor="cep" className="block text-sm font-medium leading-6 text-gray-900">
                        CEP
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <input
                          type="text"
                          {...register('cep')}
                          id="cep"
                          onChange={(e) => setValue('cep', cepMask(e.target.value))}
                          disabled={true}
                          autoComplete="cep"
                          className={classNames(
                            errors.cep ? 'pr-10 text-red-900 ring-red-300 placeholder:text-red-300  focus:ring-red-500' : 'mt-2 px-3 text-gray-900 shadow-sm  ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600',
                            'block w-full rounded-md border-0 py-1.5 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:leading-6 sm:text-sm'
                          )}
                        />
                        {errors.cep && <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                        </div>}
                      </div>
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="endereco" className="block text-sm font-medium leading-6 text-gray-900">
                        Endereço
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <input
                          type="text"
                          {...register('endereco')}
                          id="endereco"
                          onChange={(e) => setValue('endereco', e.target.value)}
                          disabled={true}
                          autoComplete="endereco"
                          className={classNames(
                            errors.endereco ? 'pr-10 text-red-900 ring-red-300 placeholder:text-red-300  focus:ring-red-500' : 'mt-2 px-3 text-gray-900 shadow-sm  ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600',
                            'block w-full rounded-md border-0 py-1.5 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:leading-6 sm:text-sm'
                          )}
                        />
                        {errors.endereco && <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                        </div>}
                      </div>
                    </div>

                    <div className="col-span-6 sm:col-span-2">
                      <label htmlFor="bairro" className="block text-sm font-medium leading-6 text-gray-900">
                        Bairro
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <input
                          type="text"
                          {...register('bairro')}
                          id="bairro"
                          onChange={(e) => setValue('bairro', e.target.value)}
                          disabled={true}
                          autoComplete="bairro"
                          className={classNames(
                            errors.bairro ? 'pr-10 text-red-900 ring-red-300 placeholder:text-red-300  focus:ring-red-500' : 'mt-2 px-3 text-gray-900 shadow-sm  ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600',
                            'block w-full rounded-md border-0 py-1.5 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:leading-6 sm:text-sm'
                          )}
                        />
                        {errors.bairro && <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                        </div>}
                      </div>
                    </div>

                    <div className="col-span-6 sm:col-span-1">
                      <label htmlFor="uf" className="block text-sm font-medium leading-6 text-gray-900">
                        UF
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <input
                          type="text"
                          {...register('uf')}
                          id="uf"
                          autoComplete="uf"
                          onChange={(e) => setValue('uf', e.target.value)}
                          disabled={true}
                          className={classNames(
                            errors.uf ? 'pr-10 text-red-900 ring-red-300 placeholder:text-red-300  focus:ring-red-500' : 'mt-2 px-3 text-gray-900 shadow-sm  ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600',
                            'block w-full rounded-md border-0 py-1.5 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:leading-6 sm:text-sm'
                          )}
                        />
                        {errors.uf && <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                        </div>}
                      </div>
                    </div>


                    <div className="col-span-6 sm:col-span-2">
                      <label htmlFor="municipio" className="block text-sm font-medium leading-6 text-gray-900">
                        Município
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <input
                          type="text"
                          {...register('municipio')}
                          id="municipio"
                          autoComplete="municipio"
                          onChange={(e) => setValue('municipio', e.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase())}
                          disabled={true}
                          className={classNames(
                            errors.municipio ? 'pr-10 text-red-900 ring-red-300 placeholder:text-red-300  focus:ring-red-500' : 'mt-2 px-3 text-gray-900 shadow-sm  ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600',
                            'block w-full rounded-md border-0 py-1.5 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:leading-6 sm:text-sm'
                          )}
                        />
                        {errors.municipio && <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                        </div>}
                      </div>
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="complemento" className="block text-sm font-medium leading-6 text-gray-900">
                        Complemento
                      </label>
                      <input
                        type="text"
                        name="complemento"
                        id="complemento"
                        onChange={(e) => setValue('complemento', e.target.value)}
                        autoComplete="complemento"
                        className="mt-2 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-1">
                      <label htmlFor="numero" className="block text-sm font-medium leading-6 text-gray-900">
                        Número
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <input
                          type="text"
                          {...register('numero')}
                          id="numero"
                          autoComplete="numero"
                          onChange={(e) => setValue('numero', e.target.value)}
                          disabled={false}
                          className={classNames(
                            errors.numero ? 'pr-10 text-red-900 ring-red-300 placeholder:text-red-300  focus:ring-red-500' : 'mt-2 px-3 text-gray-900 shadow-sm  ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600',
                            'block w-full rounded-md border-0 py-1.5 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:leading-6 sm:text-sm'
                          )}
                        />
                        {errors.numero && <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                        </div>}
                      </div>
                    </div>

                    <div className="col-span-6 sm:col-span-2">
                      <label htmlFor="pais" className="block text-sm font-medium leading-6 text-gray-900">
                        País
                      </label>
                      <input
                        type="text"
                        name="pais"
                        id="pais"
                        defaultValue={'Brasil'}
                        disabled={true}
                        autoComplete="pais"
                        className="mt-2 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-1">
                      <label htmlFor="telefone" className="block text-sm font-medium leading-6 text-gray-900">
                        Telefone
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <input
                          type="text"
                          {...register('telefone')}
                          id="telefone"
                          autoComplete="telefone"
                          value={telefone}
                          onChange={(e) => setTelefone(phoneMask(e.target.value))}
                          maxLength={14}
                          className={classNames(
                            errors.telefone ? 'pr-10 text-red-900 ring-red-300 placeholder:text-red-300  focus:ring-red-500' : 'mt-2 px-3 text-gray-900 shadow-sm  ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600',
                            'block w-full rounded-md border-0 py-1.5 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:leading-6 sm:text-sm'
                          )}
                        />
                        {errors.telefone && <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                        </div>}
                      </div>
                    </div>

                    <div className="col-span-6 sm:col-span-1">
                      <label htmlFor="ramal" className="block text-sm font-medium leading-6 text-gray-900">
                        Ramal
                      </label>
                      <input
                        type="text"
                        name="ramal"
                        id="ramal"
                        defaultValue={''}
                        onChange={(e) => setValue('ramal', e.target.value)}
                        autoComplete="ramal"
                        className="mt-2 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-1">
                      <label htmlFor="celular" className="block text-sm font-medium leading-6 text-gray-900">
                        Celular
                      </label>
                      <input
                        type="text"
                        name="celular"
                        id="celular"
                        value={celular}
                        onChange={(e) => setCelular(phoneMask(e.target.value))}
                        maxLength={15}
                        autoComplete="celular"
                        className="mt-2 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>

                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Cadastrar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Transition >
  )
}
