import http from "utils/http-client";

const info = () => http.get("/proposta");
const find = (id) => http.get(`/proposta/${id}`);
const create = (data) => http.post("/proposta", data);
const search = (data) => http.post("/proposta/produtos", data);
const localProducts = (data) => http.post("/product/findAll", data);
const listar = (data) => http.post("/proposta/listar", data);
const detalhes = (data) => http.post("/proposta/detalhes", data);
const produtoInfo = (data) => http.post("/proposta/produto/info", data);
const addProduto = (data) => http.post("/proposta/produto", data);
const observacao = (data) => http.post("/proposta/observacao", data);
const delProduto = (data) => http.post(`/proposta/delete`, data);
const delProposta = (data) => http.post(`/proposta/del`, data);
const update = (data) => http.post(`/proposta/update`, data);
const aprovar = (data) => http.post(`/proposta/aprovar`, data);
const impressao = (data) => http.post("/proposta/impressao", data);

const methods = {
  observacao,
  localProducts,
  update,
  aprovar,
  delProposta,
  detalhes,
  listar,
  impressao,
  delProduto,
  find,
  addProduto,
  produtoInfo,
  search,
  create,
  info,
};

export default methods;
