import { Fragment, useState, useEffect } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import useDidMountEffect from 'hooks/use-did-mount-effect';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function AutoComplete({ label, placeholder, data, fetchTerm, currentValue, invalid }) {
  const [selected, setSelected] = useState({})
  const [searchTerm, setSearchTerm] = useState('')
  const [searchResults, setSearchResults] = useState(data)

  useEffect(() => {
    if (searchTerm !== undefined) {
      const delayDebounceFn = setTimeout(() => {
        fetchTerm(searchTerm)
      }, 500)
      return () => clearTimeout(delayDebounceFn)
    }

  }, [searchTerm])

  useDidMountEffect(() => {
    currentValue(selected)
  }, [selected])

  useDidMountEffect(() => {
    setSelected({})
    setSearchResults(data)
  }, [data])

  // useEffect(() => {
  //   fetchTerm('')
  // }, [])

  return (
    <div className="">
      <Combobox value={selected} onChange={setSelected} >
        <Combobox.Label className="block text-sm font-medium text-gray-900 pb-1">{label}</Combobox.Label>
        <div className="relative mt-1">
          <div className="relative w-full cursor-default overflow-hidden rounded-md border-0 bg-white text-left shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            <Combobox.Button as="div" className="inset-y-0 right-0 flex items-center pr-2 border-1 border-solid border-black">
              <Combobox.Input
                style={{ border: '1px solid #cececece', borderRight: 'none' }}
                className={classNames(
                  invalid ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500' : 'placeholder:text-gray-300 text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600',
                  'cursor-pointer placeholder:text-sm placeholder:italic block w-full rounded-none rounded-l-md border-0 py-1.5 ring-inset ring-gray-300 focus:ring-2 shadow-sm focus:ring-inset sm:leading-6 sm:text-sm'
                )}
                autoComplete="off"
                displayValue={(item) => item.id ? `${item.id} - ${item.descricao}` : null}
                onChange={(event) => setSearchTerm(event.target.value)}
                placeholder={placeholder}
              />

              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400 cursor-pointer"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setSearchTerm()}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50">
              {searchResults.length === 0 && searchTerm !== '' ? (
                <div className="relative cursor-pointer select-none py-2 px-4 text-gray-700">
                  Nenhum resultado.
                </div>
              ) : (
                searchResults.map((result) => (
                  <Combobox.Option
                    key={result.id}
                    className={({ active }) =>
                      `relative cursor-pointer select-none py-2 pl-10 pr-4 ${active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                      }`
                    }
                    value={result}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                            }`}
                        >
                          {result.id + ' - ' + result.descricao}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-indigo-600'
                              }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  )
}
