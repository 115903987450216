window.onscroll = function (e) {
  const elem = document.getElementById("quoteBox");

  if (elem) {
    var distanceScrolled = document.documentElement.scrollTop;
    if (distanceScrolled > 636) {
      if (elem.parentNode.clientHeight - (distanceScrolled - 533) > 280)
        elem.style.marginTop = distanceScrolled - 533 + 'px';
    } else {
      elem.style.marginTop = '9px';
    }
  }
}

const convertGBtoT = (gb) => `${gb < 1000 ? gb.toFixed(2) : (gb * 0.001).toFixed(2)} ${gb < 1000 ? 'GB' : 'T'}`

export default function QuoteBox({ gb = 0, cost }) {
  return (
    <div id="quoteBox" style={{ transition: 'margin 700ms ease 0s' }} className="m-w-[245px] rounded h-[280px] mt-[9px] gap-2 col-span-3 py-2 px-2 border text-center flex items-center justify-center flex-col">
      <div className="col-span-9 sm:col-span-9">
        <h5 className="text-md">Ingestão de dados estimados:</h5>
        <h4 className='pt-2 text-xl font-semibold leading-7'>{convertGBtoT(gb)}/dia</h4>
      </div>
      <div className="col-span-9 sm:col-span-9">
        <h5 className="text-md">Subscrição Mensal Estimada:</h5>
        <h4 className='pt-2 text-xl font-semibold leading-7'>R$ {cost}</h4>
      </div>
      {/* <div className="pt-4 col-span-9 sm:col-span-9">
        <div
          className="cursor-pointer w-[200px] inline-flex justify-center rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Adicionar
        </div>
      </div> */}
    </div>
  );
}
