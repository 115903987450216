import { Fragment, useState, useEffect } from 'react';
import { Transition } from '@headlessui/react'
import {
  BuildingOfficeIcon,
  CheckCircleIcon
} from '@heroicons/react/24/outline'
import authService from "services/auth.service"

function Dashboard() {
  const authUser = authService.getAuthUser();
  const [isShowing, setIsShowing] = useState(false)

  useEffect(() => {
    setTimeout(() => setIsShowing(true), 100);
  }, []);

  return (
    <Transition show={isShowing}>
      <div className="py-6">

        {/* Header */}
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-[1000ms] transform"
            enterFrom="-translate-y-2 opacity-0"
            enterTo="translate-y-0 opacity-100"
            leave="transition ease-in-out duration-[1000ms] transform"
            leaveFrom="translate-y-0 opacity-100"
            leaveTo="-translate-y-2 opacity-0"
          >
            <div className="bg-white shadow lg:border-t lg:border-gray-100">
              <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
                <div className="py-6 md:flex md:items-center md:justify-between">
                  <div className="min-w-0 flex-1">
                    {/* Profile */}
                    <div className="flex items-center">
                      <img
                        className="hidden h-16 w-16 rounded-full sm:block"
                        src={authUser?.user?.image ? '/img/' + authUser.user.image : '/img/avatar.jpg'}
                        alt=""
                      />
                      <div>
                        <div className="flex items-center">
                          <img
                            className="h-16 w-16 rounded-full sm:hidden"
                            src={authUser?.user?.image ? '/img/' + authUser.user.image : '/img/avatar.jpg'}
                            alt=""
                          />
                          <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                            Bom dia, {authUser.user.name}
                          </h1>
                        </div>
                        <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                          <dt className="sr-only">Company</dt>
                          <dd className="flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6">
                            <BuildingOfficeIcon
                              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                              aria-hidden="true"
                            />
                            Parceiro
                          </dd>
                          <dt className="sr-only">Account status</dt>
                          <dd className="mt-3 flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6 sm:mt-0">
                            <CheckCircleIcon
                              className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                              aria-hidden="true"
                            />
                            Verified account
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>

        <Transition.Child
          enter="transition ease-in-out duration-[1000ms] transform"
          enterFrom="-translate-y-2 opacity-0"
          enterTo="translate-y-0 opacity-100"
          leave="transition ease-in-out duration-[1000ms] transform"
          leaveFrom="translate-y-0 opacity-100"
          leaveTo="-translate-y-2 opacity-0"
        >
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-8">
            <Transition.Child
              enter="transition ease-in-out duration-[1000ms] transform"
              enterFrom="-translate-y-2 opacity-0"
              enterTo="translate-y-0 opacity-100"
              leave="transition ease-in-out duration-[1000ms] transform"
              leaveFrom="translate-y-0 opacity-100"
              leaveTo="-translate-y-2 opacity-0"
            >
              <iframe
                title="example"
                src="https://www.cisp1.io"
                className="border-none block w-full h-[600px]"
                style={{ border: 'none' }}
              />

            </Transition.Child>
          </div>
        </Transition.Child>

      </div>
    </Transition>
  );
}

export default Dashboard;