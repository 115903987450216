import http from "utils/http-client";

const login = (data) => {
  return http.post("/auth/signin", data, {
    transformResponse: [
      (result) => {
        const parsed = JSON.parse(result);
        localStorage.setItem("authUser", JSON.stringify(parsed));
        return parsed;
      },
    ],
  });
};

const register = (data) => {
  return http.post("/auth/signup", data);
};

const profile = () => {
  return http.get("/auth/me");
};

const logout = () => {
  return http.get("/auth/logout", null, {
    transformResponse: [
      (result) => {
        // localStorage.removeItem("authUser");
        return JSON.parse(result);
      },
    ],
  });
};

const clearTokens = () => {
  localStorage.removeItem("authUser");
};

const getAuthUser = () => {
  const cookie = JSON.parse(localStorage.getItem("authUser"));
  const now = new Date();

  if (
    !cookie ||
    !cookie.access_token ||
    !cookie.expires_in ||
    (cookie.expires_in < now.getTime() / 1000)
  ) {
    localStorage.removeItem("authUser")
    return
  }

  return cookie
};

const methods = {
  login,
  register,
  profile,
  logout,
  getAuthUser,
  clearTokens
};

export default methods;
