import QuoteBox from 'components/QuoteBox';
import { useState, useEffect } from 'react'
import { currencyMask, numberMask } from 'utils/masks';

export default function Microsoft365Quote({ setGBs, setSelectedDevices, precoRevenda }) {
  const eps = 0.5

  const [quantity, setQuantity] = useState(0)
  const [totalQuote, setTotalQuote] = useState(0)
  const [gb, setGB] = useState(0)

  useEffect(() => {
    let estimated = gb * 2500

    if (quantity == 0) {
      setTotalQuote(0)
      precoRevenda(0)
    } else {
      setTotalQuote(currencyMask((estimated).toFixed(2)))
      precoRevenda(currencyMask((estimated).toFixed(2)))
      setGBs(gb)
      setSelectedDevices({
        nome: 'Microsoft 365',
        quantity: quantity,
        estimated,
        gb,
        eps: quantity * eps
      })
    }
  }, [quantity]);

  return (
    <div className="">
      <h2 className="text-lg font-bold text-gray-900">Microsoft 365 - Calculadora</h2>
      <p className="mt-6 text-sm leading-8 text-gray-600">
        Obtenha uma estimativa da quantidade diária de dados ingeridos do seu ambiente do Office 365, com base no número de usuários. Os resultados podem ser exportados como um PDF para seu próprio uso ou para obter uma cotação.
      </p>

      <div className='grid grid-cols-12 gap-9 mt-6 items-center'>

        <div className="grid grid-cols-12 col-span-9 gap-9">
          <div className="col-span-1 sm:col-span-5">
            <div className="rounded-md shadow-sm">
              <label htmlFor="quantity" className="block text-sm font-medium leading-6 text-gray-700">
                Número de contas de usuário:
              </label>
              <input
                type="number"
                id="quantity"
                onChange={(e) => {
                  setQuantity(numberMask(e.target.value))
                  setGB((e.target.value / 100) * 0.25)
                }}
                min={0}
                max={1000}
                value={quantity}
                autoComplete="quantity"
                className="w-[100%] rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="col-span-1 sm:col-span-2 pl-3 ">
            <label htmlFor="quantity" className="block text-sm font-medium leading-6 text-gray-700">
              EPS
            </label>
            <p className='pt-1'>{eps * quantity}</p>
          </div>
          <div className="col-span-1 sm:col-span-2">
            <label htmlFor="quantity" className="block text-sm font-medium leading-6 text-gray-700">
              GB/dia
            </label>
            <p className='pt-1'>{gb}</p>
          </div>
        </div>
        <QuoteBox
          gb={gb}
          cost={totalQuote}
        />
      </div>

    </div>
  )
}
