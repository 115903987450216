import { Fragment, useState, useEffect } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Selector({ label, options, onChange, disabled, invalid, value }) {
  const [selected, setSelected] = useState({});

  const handleChange = event => {
    const selection = options.filter((option) => option.id === event.id)[0]
    setSelected(selection)
    onChange(selection)
  };

  useEffect(() => {
    if(value > 0 || value == "00"){
      let selection = options.filter((option) => option.id == value)[0]
      setSelected(selection)
    }else{
      setSelected({
        id: 0, option: <i style={{ color: '#d1d1d1', fontSize: '0.875rem' }}>Selecione uma opção</i>
      })
    }
  }, [options, value])

  return (
    <Listbox disabled={disabled} value={selected} onChange={handleChange}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium text-gray-900 pb-1">{label}</Listbox.Label>
          <div className="relative mt-1">
            <Listbox.Button
              className={classNames(
                invalid ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500' : 'border-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 ',
                'cursor-pointer relative w-full rounded-md border bg-white py-2 pl-3 pr-10 text-left shadow-sm sm:text-sm text-[0.97rem] leading-[1.1em]'
              )}
            // className="cursor-pointer relative w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 "
            >
              <span className="block truncate">{selected.option}</span>
              <span
                className={classNames(
                  invalid ? 'invisible' : 'visible',
                  'pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'
                )}
              // className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
              >
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-[115px] w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'absolute bg-indigo-600 text-white' : 'text-gray-900',
                        'cursor-pointer relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {option.option}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
            <div
              className={classNames(
                invalid ? 'visible' : 'invisible',
                'pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'
              )}>
              <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
            </div>
          </div>
        </>
      )}
    </Listbox>
  )
}
