import { useEffect, useState, useContext } from 'react';
import Select from 'components/Select';
import clienteService from 'services/cliente.service';
import useDidMountEffect from 'hooks/use-did-mount-effect';
import propostaService from 'services/proposta.service';
import { format, addDays } from 'date-fns'

import NotificationContext from 'context/notification';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import DatePicker from 'components/DatePicker';

const tiposDeFaturamento = [
  { id: 'cliente', title: 'Cliente Final' },
  { id: 'revenda', title: 'Revenda' },
]

export default function Step1({ next, callback }) {
  const notificationCtx = useContext(NotificationContext);
  const today = new Date()

  const [clientes, setClientes] = useState([])
  const [endsInstalacao, setEndsInstalacao] = useState([])
  const [priceTable, setPriceTable] = useState(5)
  const [duration, setDuration] = useState(30)
  const [planos, setPlanos] = useState([])
  const [fases, setFases] = useState([])
  const [pagamento, setPagamento] = useState()
  const [endereco, setEndereco] = useState([])

  const [condPagamento, setCondPagamento] = useState([])
  const [cliente, setCliente] = useState(null)
  const [tipo, setTipo] = useState('cliente')
  const [dataCadastro,] = useState(format(today, 'dd/MM/yyyy'))
  const [dataPeriodo, setDataPeriodo] = useState(format(today, 'dd/MM/yyyy'))
  const [prevFechamento, setPrevFechamento] = useState(format(addDays(today, 1), 'dd/MM/yyyy'))
  const [fim, setFim] = useState(format(addDays(today, 30), 'dd/MM/yyyy'))

  const schema = Yup.object().shape({
    cliente: Yup.string().required(),
    instalacao: Yup.string().required(),
    fase: Yup.string().required(),
    plano: Yup.string().required(),
    distribuidor: Yup.string().required(),
    pagamento: Yup.string().required(),
  });

  const { register, handleSubmit, formState: { errors }, setValue, getValues, setError, reset } = useForm({
    mode: 'all',
    resolver: yupResolver(schema)
  });

  const handleValidSubmit = async (data) => {
    try {
      const response = await propostaService.create({
        ...data,
        dataCadastro,
        prevFechamento,
        dataPeriodo,
        fim,
        priceTable,
        duration
      });
      callback({
        "id": response.data.item.codigo,
        "cliente": response.data.item.cliente,
        "recnum": response.data.item.recnum,
        "tipo": tipo,
        dataCadastro,
        prevFechamento,
        dataPeriodo,
        fim,
        priceTable,
        duration,
        ...data,
      })
      next()
    } catch (error) {
      console.log(error);
    }

  };

  const getClientes = async () => {
    const response = await clienteService.findAll();
    setClientes(response.data)
  }

  const getInfo = async () => {
    const response = await propostaService.info();
    setFases(response.data.fases)
    setPlanos(response.data.planos)
    setCondPagamento(response.data.pagamentos)
    setPagamento(2)
  }

  const getEnderecos = async () => {
    setValue('instalacao', '')
    const response = await clienteService.findAddress(cliente);

    setEndsInstalacao(response.data)
  }

  useDidMountEffect(() => {
    setEndereco(0);
    setValue('instalacao', 0)
  }, [endsInstalacao])
  
  useDidMountEffect(() => {
    getEnderecos()
  }, [cliente])

  useEffect(() => {
    setValue('plano', 4)
    setValue('fase', 5)
    setValue('distribuidor', 1)
    setValue('pagamento', 2)
    getInfo()
    getClientes()
  }, [])

  return (
    <>
      <form className="space-y-6" onSubmit={handleSubmit(handleValidSubmit)}>
        <div className="shadow sm:rounded-md">

          <div className="space-y-6 bg-white py-6 px-4 sm:p-6 sm:pb-0 pb-0">
            <label className="text-base font-semibold text-gray-900">Tipo de Faturamento</label>
            <fieldset className="mt-4">
              <legend className="sr-only">Notification method</legend>
              <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                {tiposDeFaturamento.map((tipo) => (
                  <div key={tipo.id} className="flex items-center">
                    <input
                      id={tipo.id}
                      name="tipo"
                      type="radio"
                      defaultChecked={tipo.id === 'cliente'}
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer"
                      onChange={(e) => setTipo(e.target.id)}
                    />
                    <label htmlFor={tipo.id} className="ml-3 block text-sm font-medium leading-6 text-gray-900 cursor-pointer">
                      {tipo.title}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>

          <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
            <div style={{ borderTop: '1px solid #efefef' }}></div>
            <div className="grid grid-cols-6 gap-6">

              <div className="col-span-6 sm:col-span-6">
                <h3 className="text-base font-semibold leading-6 text-gray-900">Cliente</h3>
                <p className="mt-1 text-sm text-gray-500">Selecione o cliente e seus respectivos endereços.</p>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <input type="hidden" {...register('cliente')} />
                <Select
                  label='Cliente'
                  options={clientes}
                  invalid={errors.cliente}
                  onChange={(e) => {
                    setCliente(e.id)
                    setValue('cliente', e.id)
                  }}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <input type="hidden" {...register('instalacao')} />
                <Select
                  label='Endereço de Faturamento'
                  options={endsInstalacao}
                  invalid={errors.instalacao}
                  onChange={(e) => setValue('instalacao', e.id)}
                  value={endereco}
                />
              </div>

            </div>
          </div>

          <div className="space-y-6 bg-white py-6 px-4 sm:p-6 ">
            <div style={{ borderTop: '1px solid #efefef' }}></div>

            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-6">
                <h3 className="text-base font-semibold leading-6 text-gray-900">Operação</h3>
                <p className="mt-1 text-sm text-gray-500">Selecione as demais informações da operação.</p>
              </div>
              
              <div className="col-span-6 sm:col-span-3">
                <input type="hidden" {...register('pagamento')} />
                <Select
                  label='Condições de Pagamento'
                  options={condPagamento}
                  invalid={errors.pagamento}
                  onChange={(e) => setValue('pagamento', e.id)}
                  value={pagamento}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <DatePicker
                  label={'Previsão de Fechamento'}
                  onChange={(e) => setPrevFechamento(format(addDays(e, 1), 'dd/MM/yyyy'))}
                />
              </div>

            </div>

          </div>
        </div>
        <div className="py-2 text-end ">
          <button
            type="submit"
            className="w-[150px] inline-flex justify-center items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Avançar
          </button>
        </div>
      </form>
    </>
  );
}
