import { useEffect, useState, useContext, Fragment } from 'react';
import Select from 'components/Select';
import { TrashIcon } from "@heroicons/react/24/outline";
import AutoComplete from 'components/AutoComplete';
import propostaService from 'services/proposta.service';
import { Dialog, Transition } from '@headlessui/react'
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns'
import SoCQuote from 'pages/SocQuote';
import Microsoft365Quote from 'pages/Microsoft365Quote';
import SoCLightQuote from 'pages/SocLightQuote';
import SocAnalyticsReportsQuote from 'pages/SocAnalyticsReportsQuote';
import authService from "services/auth.service"

import NotificationContext from 'context/notification';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import useDidMountEffect from 'hooks/use-did-mount-effect';

import { currencyMask } from 'utils/masks';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import { usePromiseTracker } from "react-promise-tracker";
import Spinner from 'components/Spinner';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const categorias = [
  { id: 0, option: 'Todas' },
  { id: 1, option: 'Serviços Gerenciados' },
]

const subCategorias = [
  { id: 0, option: 'Todas' },
  { id: 1, option: 'Serviços de Implementação' },
  { id: 2, option: 'Serviços de Suporte' },
  { id: 3, option: 'Serviços de NOC' },
  { id: 5, option: 'Serviços de Treinamento' }
]

const fabricantes = [
  { id: 0, option: 'Todos' },
  { id: 111, option: 'CISP1 Services' },
]

const condPagamento = [
  {
    "id": "1",
    "option": "DEPÓSITO EM CONTA (A VISTA)"
  },
  {
    "id": "2",
    "option": "BOLETO (30 DIAS)"
  },
  {
    "id": "3",
    "option": "BOLETO (30/60 DIAS)"
  },
  {
    "id": "4",
    "option": "BOLETO (30/60/90 DIAS)"
  },
  {
    "id": "5",
    "option": "BOLETO À VISTA"
  },
  {
    "id": "00",
    "option": "REMESSA SEM COBRANÇA"
  }
]

export default function DetalhesXaaS() {
  const authUser = authService.getAuthUser();
  const notificationCtx = useContext(NotificationContext);
  const { promiseInProgress } = usePromiseTracker();

  const { id } = useParams();
  const navigate = useNavigate();

  const openModal = () => {
    setOpen(true)
  }

  const [open, setOpen] = useState(false)
  const [isShowing, setIsShowing] = useState(false)
  const [produtos, setProdutos] = useState([])
  const [items, setItems] = useState([])
  const [tipo, setTipo] = useState()
  const [valorFinal, setValorFinal] = useState(0)
  const [quantidade, setQuantidade] = useState(1)
  const [valorProduto, setValorProduto] = useState(undefined)
  const [produtoSelecionado, setProdutoSelecionado] = useState([])
  const [categoria, setCategoria] = useState('')
  const [subCategoria, setSubCategoria] = useState('')
  const [fabricante, setFabricante] = useState('')
  const [fase, setFase] = useState()
  const [contrato, setContrato] = useState(null)
  const [condicao, setCondicao] = useState([])
  const [impressao, setImpressao] = useState('')
  const [observacao, setObservacao] = useState()
  const [pedidoInfo, setPedidoInfo] = useState({})

  const [precoRevenda, setPrecoRevenda] = useState(0)
  const [fullDevices, setFullDevices] = useState([])
  const [lightDevices, setLightDevices] = useState([])
  const [analyticsDevices, setAnalyticsDevices] = useState([])
  const [officeDevices, setOfficeDevices] = useState([])
  const [fullGB, setFullGB] = useState(0)
  const [lightGB, setLightGB] = useState(0)
  const [analyticsGB, setAnalyticsGB] = useState(0)
  const [officeGB, setOfficeGB] = useState(0)

  Yup.addMethod(Yup.string, "minValue", function (minValue) {
    return this.test(`valor`, minValue, function (value) {
      const { path, createError } = this;
      if (tipo !== 'Cliente') return true

      if (!observacao) {
        if (!value)
          notificationCtx.error('Defina o preço e quantidade do produto');

        if (!produtoSelecionado.valor)
          notificationCtx.error('Selecione um produto');

        if ((parseFloat(value) < parseFloat(produtoSelecionado.valor)))
          notificationCtx.error('Defina um preço final maior ou igual ao preço de revenda');

        if ((parseFloat(value) > parseFloat(produtoSelecionado.valor) * 2))
          notificationCtx.error('Defina um preço final menor que 200% do preço de revenda');

        return (
          value &&
          produtoSelecionado.valor &&
          (parseFloat(value) >= parseFloat(produtoSelecionado.valor)) &&
          (parseFloat(value) < parseFloat(produtoSelecionado.valor) * 2) ||
          createError({ path, message: 'min Value' })
        );
      } else {
        if (tipo !== 'Cliente') return true

        if (!value)
          notificationCtx.error('Defina o preço do produto');

        if (!precoRevenda)
          notificationCtx.error('Selecione um produto');

        if ((parseFloat(value) < parseFloat(precoRevenda)))
          notificationCtx.error('Defina um preço final maior ou igual ao preço de revenda');

        if ((parseFloat(value) > parseFloat(precoRevenda) * 2))
          notificationCtx.error('Defina um preço final menor que 200% do preço de revenda');

        return (
          value &&
          precoRevenda &&
          (parseFloat(value) >= parseFloat(precoRevenda)) &&
          (parseFloat(value) < parseFloat(precoRevenda) * 2) ||
          createError({ path, message: 'min Value' })
        );
      }
    });
  });

  const schemaAddProduto = Yup.object().shape({
    produto: Yup.string().required(),
    valor: Yup.string().minValue(produtoSelecionado.valor)
  });

  const { register, handleSubmit, formState: { errors }, setValue, getValues, reset } = useForm({
    mode: 'all',
    resolver: yupResolver(schemaAddProduto)
  });

  const aprovarPedido = async () => {
    const confirm = window.confirm('Deseja aprovar o pedido?')
    if (confirm) {
      await propostaService.aprovar({
        "recnum": pedidoInfo.recnum,
      });
      notificationCtx.success(`Pedido Aprovado!`);
      navigate('/xaas/consultar')
    }
  }

  const exportPdf = () => {
    window.scrollTo(0, 0)
    html2canvas(document.querySelector("#capture")).then(canvas => {
      const img = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: 'p',
        unit: 'px',
        format: [canvas.width * 0.57, canvas.height * 0.75]
      });
      pdf.addImage(img, 'PNG', 0, 0);
      pdf.save("download.pdf");
    });
  }

  const clearProductFields = () => {
    setValorProduto(undefined)
    setProdutoSelecionado({})
    setValue('produto', '')
    setValue('quantidade', '')
    setValue('valor', '')
    reset()
  }

  const setProdutoInfo = async (item) => {
    if (item.id) {
      setValorProduto(undefined)
      const response = await propostaService.produtoInfo({
        produto: item.id,
        proposta: pedidoInfo.recnum,
      });
      setValue('valor', '')
      setValue('produto', item.id)
      setProdutoSelecionado({ ...item, valor: parseFloat(response.data.valor).toFixed(2).replace('.', ',') })
    }
  }

  const searchProdutos = async (text) => {
    let response
    if (!observacao) {
      console.log({
        "name": text,
        "category": categoria == 'Todas' ? '' : categoria,
        "subcategory": subCategoria == 'Todas' ? '' : subCategoria,
        "manufacturer": fabricante == 'Todos' ? '' : fabricante,
        "skipsoc": "1"
      });
      response = await propostaService.localProducts({
        "name": text,
        "category": categoria == 'Todas' ? '' : categoria,
        "subcategory": subCategoria == 'Todas' ? '' : subCategoria,
        "manufacturer": fabricante == 'Todos' ? '' : fabricante,
        "skipsoc": "1"
      });
    } else {
      response = await propostaService.localProducts({
        "name": text,
        "subcategory": 'Serviços de SOC',
      });
    }

    setProdutos(response.data)
  }

  const updatePedido = async () => {
    await propostaService.update({
      id,
      fase,
      condicao
    });
    notificationCtx.success(`Pedido Atualizado!`);
  }

  const getImpressao = async () => {
    const response = await propostaService.impressao({
      "recnum": pedidoInfo.recnum
    });
    setImpressao(response.data)
    window.scrollTo(0, 0)
  }

  const addProduto = async () => {
    setImpressao('')
    try {
      let response
      if (!observacao) {
        response = await propostaService.addProduto({
          "produto": produtoSelecionado.id,
          "quantidade": quantidade ? quantidade : 1,
          "valor_unitario": tipo === 'Cliente' ? valorProduto : produtoSelecionado.valor,
          "valor": produtoSelecionado.valor,
          "proposta": pedidoInfo.recnum,
          "tabpreco": pedidoInfo.tabela_preco,
          "pagamento": pedidoInfo.condpagto,
          "plano": pedidoInfo.tipo,
          "fase": pedidoInfo.fase_negociacao,
          "instalacao": 0,
          "cliente": pedidoInfo.cliente,
          "dataCadastro": format(new Date(pedidoInfo.created_at), 'dd/MM/yyyy'),
          "prevFechamento": format(new Date(pedidoInfo.prev_fechamento), 'dd/MM/yyyy'),
          "dataPeriodo": format(new Date(pedidoInfo.data_inicio), 'dd/MM/yyyy'),
          "fim": format(new Date(pedidoInfo.data_fim), 'dd/MM/yyyy'),
          "duration": pedidoInfo.dias_contrato,
          "venda": produtoSelecionado.type == 'recurring' ? 0 : 1
        });
      } else {
        let obs = JSON.parse(observacao)
        if (fullDevices.length > 0) {
          obs.full = {
            gb: fullGB,
            devices: fullDevices
          }
        }

        if (lightDevices.length > 0) {
          obs.light = {
            gb: lightGB,
            devices: lightDevices
          }
        }

        if (analyticsDevices.length > 0) {
          obs.analytics = {
            gb: analyticsGB,
            devices: analyticsDevices
          }
        }

        if (officeDevices.quantity > 0) {
          obs.office = {
            gb: officeGB,
            devices: officeDevices
          }
        }

        response = await propostaService.addProduto({
          "produto": produtoSelecionado.id,
          "quantidade": 1,
          "valor_unitario": tipo === 'Cliente' ? valorProduto : precoRevenda,
          "valor": precoRevenda,
          "proposta": pedidoInfo.recnum,
          "tabpreco": 5,
          "pagamento": pedidoInfo.condpagto,
          "plano": pedidoInfo.tipo,
          "fase": pedidoInfo.fase_negociacao,
          "instalacao": 0,
          "cliente": pedidoInfo.cliente,
          "dataCadastro": format(new Date(pedidoInfo.created_at), 'dd/MM/yyyy'),
          "prevFechamento": format(new Date(pedidoInfo.prev_fechamento), 'dd/MM/yyyy'),
          "dataPeriodo": format(new Date(pedidoInfo.data_inicio), 'dd/MM/yyyy'),
          "fim": format(new Date(pedidoInfo.data_fim), 'dd/MM/yyyy'),
          "duration": pedidoInfo.dias_contrato,
          "venda": 1,
          "observacao": obs
        });
      }

      setItems(response.data.produtos)
      calculaTotal(response.data.produtos)

      notificationCtx.success(`Produto ${produtoSelecionado.descricao.slice(0, 12) + '...'} adicionado`);
    } catch (error) {
      console.log(error);
      notificationCtx.error(`Produto já adicionado`);
    }

    clearProductFields()
  }

  const calculaTotal = (produtos) => {
    let result = produtos.reduce((acc, b) => {
      return acc + Number(b.valor)
    }, 0);
    setValorFinal(result.toFixed(2).replace('.', ','));
  }

  const delProduto = async (id, produto) => {
    let response

    if (!observacao) {
      response = await propostaService.delProduto({
        "id_produto": id,
        "proposta": pedidoInfo.recnum,
        "tabpreco": pedidoInfo.tabela_preco,
        "pagamento": pedidoInfo.condpagto,
        "plano": pedidoInfo.tipo,
        "fase": pedidoInfo.fase_negociacao,
        "instalacao": 0,
        "cliente": pedidoInfo.cliente,
        "dataCadastro": format(new Date(pedidoInfo.created_at), 'dd/MM/yyyy'),
        "prevFechamento": format(new Date(pedidoInfo.prev_fechamento), 'dd/MM/yyyy'),
        "dataPeriodo": format(new Date(pedidoInfo.data_inicio), 'dd/MM/yyyy'),
        "fim": format(new Date(pedidoInfo.data_fim), 'dd/MM/yyyy'),
        "duration": pedidoInfo.dias_contrato,
      });
    } else {
      let obs = JSON.parse(observacao)
      if (produto == '03-IMC-0004') {
        obs.office = {
          gb: 0,
          devices: []
        }
      }

      if (produto == '03-IMC-0005') {
        obs.analytics = {
          gb: 0,
          devices: []
        }
      }

      if (produto == '03-IMC-0006') {
        obs.light = {
          gb: 0,
          devices: []
        }
      }

      if (produto == '03-IMC-0007') {
        obs.full = {
          gb: 0,
          devices: []
        }
      }

      response = await propostaService.delProduto({
        "id_produto": id,
        "proposta": pedidoInfo.recnum,
        "tabpreco": pedidoInfo.tabela_preco,
        "pagamento": pedidoInfo.condpagto,
        "plano": pedidoInfo.tipo,
        "fase": pedidoInfo.fase_negociacao,
        "instalacao": 0,
        "cliente": pedidoInfo.cliente,
        "dataCadastro": format(new Date(pedidoInfo.created_at), 'dd/MM/yyyy'),
        "prevFechamento": format(new Date(pedidoInfo.prev_fechamento), 'dd/MM/yyyy'),
        "dataPeriodo": format(new Date(pedidoInfo.data_inicio), 'dd/MM/yyyy'),
        "fim": format(new Date(pedidoInfo.data_fim), 'dd/MM/yyyy'),
        "duration": pedidoInfo.dias_contrato,
        "observacao": obs
      });
    }

    setItems(response.data.produtos)
    calculaTotal(response.data.produtos)
    notificationCtx.success(`Produto removido`);
  }

  const getPedido = async () => {
    const response = await propostaService.find(id);
    setItems(response.data)
    calculaTotal(response.data)
  }

  const detalhesPedido = async () => {
    const response = await propostaService.detalhes({ 'codigo': id });
    setContrato(response.data.contrato)
    setTipo(response.data.camposPersonalizados[0] && response.data.camposPersonalizados[0].money !== '0.00' ? 'Cliente' : 'Revenda')
    setPedidoInfo(response.data);
    setItems(response.data.produtos)
    setFase(response.data.fase_negociacao)
    setCondicao(response.data.condpagto)
    setObservacao(response.data.observacao)
  }

  const handleAddProduct = async () => {
    addProduto()
  };

  useDidMountEffect(() => {
    clearProductFields()
    searchProdutos('')
  }, [fabricante, categoria, subCategoria])


  useDidMountEffect(() => {
    searchProdutos('')
  }, [observacao])

  useEffect(() => {
    detalhesPedido()
    getPedido()
  }, [])

  useEffect(() => {
    setTimeout(() => setIsShowing(true), 100);
  }, []);

  return (
    <>
      <Transition
        show={isShowing}
        enter="transition ease-in-out duration-[1000ms] transform"
        enterFrom="-translate-y-2 opacity-0"
        enterTo="translate-y-0 opacity-100"
        leave="transition ease-in-out duration-[1000ms] transform"
        leaveFrom="translate-y-0 opacity-100"
        leaveTo="-translate-y-2 opacity-0"
      >
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="pt-10 pb-16">
            <div className="px-4 sm:px-6 lg:px-0">
              <h1 className="text-3xl font-bold tracking-tight text-gray-900">Detalhes do Pedido #{id}</h1>
            </div>
            <div className="py-6">
              <div className="shadow sm:overflow-hidden sm:rounded-md">
                <form className="space-y-6 bg-white">

                  <div className=" sm:rounded-md">

                    <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                      <div className="grid grid-cols-6 gap-6">

                        <div className="col-span-6 sm:col-span-6">
                          <div className="flex justify-between">
                            <div>
                              <h3 className="text-base font-semibold leading-6 text-gray-900">Dados Gerais</h3>
                              <p className="mt-1 text-sm text-gray-500">Informaçoes do cliente, operação e tipo do pedido.</p>
                            </div>
                            {!contrato &&
                              <button
                                type="button"
                                className="mr-3 w-[180px] h-[40px] border border-indigo-100 rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-400 shadow-sm hover:bg-indigo-100"
                                //className='bg-indigo-600 hover:bg-indigo-700 text-white w-[180px] h-[40px] inline-flex justify-center items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                                onClick={updatePedido}
                                disabled={contrato}
                              >
                                Salvar
                              </button>
                            }
                          </div>
                        </div>

                        <div className="col-span-6 sm:col-span-6">
                          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-900">Cliente</dt>
                              <dd className="mt-1 text-sm text-gray-900">{pedidoInfo.razaosocial}</dd>
                            </div>

                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-900">Tipo de Faturamento</dt>
                              <dd className="mt-1 text-sm text-gray-900">{tipo}</dd>
                            </div>

                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-900">Previsão de Fechamento</dt>
                              <dd className="mt-1 text-sm text-gray-900">{pedidoInfo.prev_fechamento ? format(new Date(pedidoInfo.prev_fechamento), 'dd/MM/yyyy') : ''}</dd>
                            </div>

                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-900">Início do Contrato</dt>
                              <dd className="mt-1 text-sm text-gray-900">{pedidoInfo.data_inicio ? format(new Date(pedidoInfo.data_inicio), 'dd/MM/yyyy') : ''}</dd>
                            </div>

                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-900">Endereço de Instalação</dt>
                              <dd className="mt-1 text-sm text-gray-900">{pedidoInfo.enderecos ? `${pedidoInfo.enderecos[0].padrao}, ${pedidoInfo.enderecos[0].padraoNumero} - ${pedidoInfo.enderecos[0].padraoComplemento.trim() ? pedidoInfo.enderecos[0].padraoComplemento + ' -' : ''} ${pedidoInfo.enderecos[0].padraoMunicipio} - ${pedidoInfo.enderecos[0].padraoUF}` : ''}</dd>
                            </div>

                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-900">Centro de Distribuição</dt>
                              <dd className="mt-1 text-sm text-gray-900">CISP1</dd>
                            </div>

                          </dl>

                          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                              <Select
                                label='Condição Pagamento'
                                value={condicao}
                                options={condPagamento}
                                onChange={(selected) => setCondicao(selected.id)}
                                disabled={contrato}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div className="shadow sm:overflow-hidden sm:rounded-md">
                    <div style={{ borderTop: '1px solid #efefef' }}></div>

                    <div className="space-y-6 bg-white py-6 px-4 sm:p-6 ">

                      {!contrato ? <div>
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Editar Produtos</h3>
                        <p className="mt-1 text-sm text-gray-500">Inclua ou remova produtos do seu pedido.</p>
                      </div> :
                        <div>
                          <h3 className="text-base font-semibold leading-6 text-gray-900">Resumo dos Produtos</h3>
                          <p className="mt-1 text-sm text-gray-500">Produtos incluídos em seu pedido.</p>
                        </div>
                      }
                      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

                        {!contrato && <>

                          {!observacao &&
                            <><div className="sm:col-span-2">
                              <Select
                                label='Fabricante'
                                options={fabricantes}
                                onChange={(selected) => setFabricante(selected.option)}
                              />
                            </div>

                              <div className="sm:col-span-2">
                                <Select
                                  label='Categoria'
                                  options={categorias}
                                  onChange={(selected) => setCategoria(selected.option)}
                                />
                              </div>

                              <div className="sm:col-span-2">
                                <Select
                                  label='Sub-Categoria'
                                  options={subCategorias}
                                  onChange={(selected) => setSubCategoria(selected.option)}
                                />
                              </div></>}

                          <div className="sm:col-span-6">
                            <input type="hidden" {...register('produto')} />
                            <AutoComplete
                              label={'Produtos'}
                              placeholder={'Busque por um produto'}
                              invalid={errors.produto}
                              data={produtos}
                              fetchTerm={searchProdutos}
                              currentValue={setProdutoInfo}
                            />
                          </div>

                          {!observacao &&
                            <>
                              <div className="sm:col-span-2">
                                <label htmlFor="valor" className="block text-sm font-medium leading-6 text-gray-900">
                                  Preço Revenda
                                </label>
                                <div className="mt-2 flex rounded-md shadow-sm">
                                  <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                                    R$
                                  </span>
                                  <input
                                    id="revenda"
                                    type="text"
                                    pattern="\d*"
                                    inputMode="numeric"
                                    value={produtoSelecionado?.valor}
                                    className='bg-gray-50 text-gray-900 shadow-sm  ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 block w-full rounded-none rounded-r-md border-0 py-1.5 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:leading-6 sm:text-sm'
                                    disabled
                                  />
                                </div>
                              </div>
                              {tipo === 'Cliente' &&
                                <>
                                  <div className="sm:col-span-2">
                                    <label htmlFor="valor" className="block text-sm font-medium leading-6 text-gray-900">
                                      Preço Cliente
                                    </label>
                                    <div className="mt-2 flex rounded-md shadow-sm">
                                      <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                                        R$
                                      </span>
                                      <input
                                        id="valor"
                                        {...register('valor')}
                                        type="text"
                                        pattern="\d*"
                                        inputMode="numeric"
                                        autoComplete="qtd"
                                        value={valorProduto}
                                        className={classNames(
                                          errors.valor ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500' : 'text-gray-900 shadow-sm  ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600',
                                          'block w-full rounded-none rounded-r-md border-0 py-1.5 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:leading-6 sm:text-sm'
                                        )}
                                        onChange={(e) => setValorProduto(currencyMask(e.target.value))}
                                      />
                                    </div>
                                  </div>
                                </>
                              }

                              <div className="sm:col-span-1">
                                <label htmlFor="quantidade" className="block text-sm font-medium leading-6 text-gray-900">
                                  Quantidade
                                </label>
                                <div className="mt-2">
                                  <input
                                    id="quantidade"
                                    {...register('quantidade')}
                                    type="number"
                                    pattern="\d*"
                                    inputMode="numeric"
                                    min={1}
                                    autoComplete="quantidade"
                                    className={classNames(
                                      errors.quantidade ? 'text-red-900 ring-red-300 placeholder:text-red-300  focus:ring-red-500' : 'mt-2 px-3 text-gray-900 shadow-sm  ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600',
                                      'block w-full rounded-md border-0 py-1.5 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:leading-6 sm:text-sm'
                                    )}
                                    onChange={(e) => setQuantidade(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="sm:col-span-1 flex self-end">
                                <button
                                  type="button"
                                  className="w-[100%] inline-flex justify-center  items-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                  onClick={handleSubmit(handleAddProduct)}
                                >
                                  {promiseInProgress ? <Spinner /> : 'Adicionar'}
                                </button>
                              </div>
                            </>
                          }
                          {observacao &&
                            <>
                              {produtoSelecionado.id &&
                                <div className="sm:col-span-6 border px-8 py-6 pb-12 rounded-md">
                                  <div className="pt-4 flex items-center">
                                    {produtoSelecionado.id == '03-IMC-0004' &&
                                      <Microsoft365Quote
                                        setGBs={setOfficeGB}
                                        setSelectedDevices={setOfficeDevices}
                                        precoRevenda={setPrecoRevenda}
                                      />
                                    }

                                    {produtoSelecionado.id == '03-IMC-0005' &&
                                      <SocAnalyticsReportsQuote
                                        setGBs={setAnalyticsGB}
                                        setSelectedDevices={setAnalyticsDevices}
                                        precoRevenda={setPrecoRevenda}
                                      />
                                    }

                                    {produtoSelecionado.id == '03-IMC-0006' &&
                                      <SoCLightQuote
                                        setGBs={setLightGB}
                                        setSelectedDevices={setLightDevices}
                                        precoRevenda={setPrecoRevenda}
                                      />
                                    }

                                    {produtoSelecionado.id == '03-IMC-0007' &&
                                      <SoCQuote
                                        setGBs={setFullGB}
                                        setSelectedDevices={setFullDevices}
                                        precoRevenda={setPrecoRevenda}
                                      />
                                    }
                                  </div>
                                </div>
                              }

                            </>
                          }

                          {observacao && <>
                            <div className="sm:col-span-2">
                              <label htmlFor="valor" className="block text-sm font-medium leading-6 text-gray-900">
                                Preço Revenda Subscrição Mensal
                              </label>
                              <div className="mt-2 flex rounded-md shadow-sm">
                                <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                                  R$
                                </span>
                                <input
                                  id="revenda"
                                  type="text"
                                  pattern="\d*"
                                  inputMode="numeric"
                                  value={precoRevenda == 0 ? '' : precoRevenda}
                                  className='bg-gray-50 text-gray-900 shadow-sm  ring-gray-300 placeholder:text-gray-400 focus:ring-indigo-600 block w-full rounded-none rounded-r-md border-0 py-1.5 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:leading-6 sm:text-sm'
                                  disabled
                                />
                              </div>
                            </div>
                            {tipo === 'Cliente' &&
                              <>

                                <div className="sm:col-span-2">
                                  <label htmlFor="valor" className="block text-sm font-medium leading-6 text-gray-900">
                                    Preço Cliente Subscrição Mensal
                                  </label>
                                  <div className="mt-2 flex rounded-md shadow-sm">
                                    <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                                      R$
                                    </span>
                                    <input
                                      id="valor"
                                      {...register('valor')}
                                      type="text"
                                      pattern="\d*"
                                      inputMode="numeric"
                                      autoComplete="qtd"
                                      value={valorProduto}
                                      className={classNames(
                                        errors.valor ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500' : 'text-gray-900 shadow-sm  ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600',
                                        'block w-full rounded-none rounded-r-md border-0 py-1.5 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:leading-6 sm:text-sm'
                                      )}
                                      onChange={(e) => setValorProduto(currencyMask(e.target.value))}
                                    />
                                  </div>
                                </div>
                              </>
                            }

                            <div className="sm:col-span-2 flex self-end">
                              <button
                                type="button"
                                className="w-[100%] inline-flex justify-center  items-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                onClick={handleSubmit(handleAddProduct)}
                              >
                                {promiseInProgress ? <Spinner /> : 'Adicionar'}
                              </button>
                            </div>
                          </>}
                        </>}

                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 sm:col-span-6">
                          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                              <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                  <tr>
                                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                      Produto
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                      Descricao
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                      Quantidade
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                      Preço Revenda
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                      Total Revenda
                                    </th>
                                    {tipo === 'Cliente' &&
                                      <>
                                        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                          Preço Cliente
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                          Total Cliente
                                        </th>
                                      </>
                                    }
                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                      <span className="sr-only">Edit</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                  {items.map((item, index) => (
                                    <tr key={item.descricao + index}>

                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{item.produto}</td>
                                      <td className="whitespace-nowrap truncate max-w-[310px] px-3 py-4 text-sm text-gray-500">{item.descricao}</td>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{parseFloat(item.quantidade).toFixed(0)}</td>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{item.valor_ativo.replace('.', ',')}</td>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{(parseFloat(item.valor_ativo.replace(',', '.')) * Number(item.quantidade)).toLocaleString('pt-br', { minimumFractionDigits: 2 })}</td>
                                      {tipo === 'Cliente' &&
                                        <>
                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{parseFloat(item.valor_unitario).toFixed(2).replace('.', ',')}</td>
                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{item.valor.replace('.', ',')}</td>
                                        </>}
                                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                        {!contrato && !observacao && <a onClick={() => delProduto(item.recnum, item.produto)} className="cursor-pointer text-red-600 hover:text-red-900">
                                          <TrashIcon className="h-4 w-4 text-red-500" /><span className="sr-only">, {item.name}</span>
                                        </a>
                                        }
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                        <div className="sm:col-span-6">
                          <div className="flex items-center justify-between pt-2">
                            <dt className="pl-1 text-sm font-medium">Total</dt>
                            <dd className="pr-1 text-sm font-medium text-gray-900">R$ {valorFinal}</dd>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </form>
              </div>
              <div className="flex justify-end mt-5">
                <button
                  type="button"
                  className="mr-3 w-[180px] border border-indigo-200 rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-500 shadow-sm hover:bg-indigo-100"
                  //className="mr-3 w-[150px] inline-flex justify-center items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={() => {
                    openModal(true)
                    getImpressao()
                  }}
                >
                  Ver Resumo
                </button>
                {!contrato && <button
                  type="button"
                  className="w-[180px] inline-flex justify-center  items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={aprovarPedido}
                >
                  Aprovar Pedido
                </button>}
              </div>
            </div>
          </div>
        </div>
      </Transition >
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-lg bg-white pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[1000px] sm:p-6">
                  {impressao ?
                    <div id="capture" className="px-4 pb-4" >
                      <div className='grid grid-cols-6 gap-6 bg-gray-50 h-[40px] text-sm mb-3 rounded-t-md'>
                        <div className='col-span-6 sm:col-span-6 flex justify-center items-center'>
                          <span className='font-bold'>{impressao.titulo}</span>
                        </div>
                      </div>
                      <div className='flex justify-between'>
                        <div className='text-sm flex flex-col'>
                          <span className='text-sm'><strong>CISP1 SOLUCOES DE TECNOLOGIA LTDA</strong></span>
                          <span><strong>CNPJ:</strong> 48.592.073/0001-46 - <strong>IE: 138.301.630.113</strong></span>
                          <span><strong>Endereço:</strong> AVENIDA BRIG FARIA LIMA , 4055, ITAIM BIBI - SAO PAULO / SP - <strong>CEP:</strong> 04538-133</span>
                          <span><strong>Telefone:</strong> (11) 2714-6900 </span>
                        </div>
                        <img
                          className="h-[50px] w-auto"
                          src={'/logo.png'}
                          alt="CISP1"
                        />
                      </div>
                      <div className="grid grid-cols-6 gap-6 text-sm mt-10">
                        <div className="col-span-6 sm:col-span-3 flex flex-col">
                          <span><strong>Razão Social:</strong> {impressao.cliente[1].split(':')[1]}</span>
                          <span><strong>CNPJ:</strong> {impressao.cliente[2].split(':')[1]}</span>
                          <span><strong>IE:</strong> {impressao.cliente[3].split(':')[1]}</span>
                          <span><strong>Telefone:</strong> {impressao.cliente[7].split(':')[1]}</span>
                        </div>
                        <div className="col-span-6 sm:col-span-3 flex flex-col">
                          <span><strong>Endereço:</strong> {impressao.cliente[4].split(':')[1]}</span>
                          <span><strong>Município:</strong> {impressao.cliente[5].split('-')[0].split(':')[1]}</span>
                          <span><strong>UF:</strong> {impressao.cliente[5].split('-')[1].split(':')[1]}</span>
                          <span><strong>CEP:</strong> {impressao.cliente[6].split(':')[1]}</span>
                        </div>
                      </div>
                      <div className="grid grid-cols-6 gap-6 text-sm mt-10">
                        <div className="col-span-6 sm:col-span-3"><strong>Condição de Pagamento:</strong> {impressao.condicao[1].split(':')[1]}</div>
                        {/* <div className="col-span-6 sm:col-span-3"><span><strong>Periodo:</strong> {impressao.condicao[0].split(':')[1]}</span></div> */}
                      </div>

                      {impressao.subscricao.produtos.length > 0 &&
                        <>
                          <div className="text-sm text-center mt-10 flex">
                            <strong>Produtos em Subscrição Mensal</strong>
                          </div>
                          <div className="mt-5 flow-root">
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                  <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                      <tr>
                                        <th scope="col" className="text-sm font-semibold text-gray-900 text-center">
                                          Item
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900 text-center">
                                          Codigo
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                          Descricao
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                          Qtde
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                          Valor
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                          Total
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                      {impressao.subscricao.produtos.map((produtos) => (
                                        <tr key={produtos.sku}>
                                          <td className="text-center px-3 py-4 text-sm text-gray-500">{produtos.id}</td>
                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{produtos.sku}</td>
                                          <td className="whitespace-nowrap truncate max-w-[310px] px-3 py-4 text-sm text-gray-500">{produtos.descricao}</td>
                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{produtos.quantidade}</td>
                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{produtos.valor}</td>
                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{produtos.total_produto}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <th className="text-center whitespace-nowrap px-3 py-2.5 text-sm text-gray-700">Total Recorrente:</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th className="whitespace-nowrap px-3 py-2.5 text-sm text-gray-700">{impressao.subscricao.total}</th>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      }

                      {impressao.addons.produtos.length > 0 &&
                        <>
                          <div className="text-sm text-center mt-8 flex">
                            <strong>Produtos para Venda</strong>
                          </div>
                          <div className="mt-5 flow-root">
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                  <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                      <tr>
                                        <th scope="col" className="text-sm font-semibold text-gray-900 text-center">
                                          Item
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                          Codigo
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                          Descricao
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                          Qtde
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                          Valor
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                          Total
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                      {impressao.addons.produtos.map((produtos) => (
                                        <tr key={produtos.sku}>
                                          <td className="text-center whitespace-nowrap px-3 py-4 text-sm text-gray-500">{produtos.id}</td>
                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{produtos.sku}</td>
                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{produtos.descricao}</td>
                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{produtos.quantidade}</td>
                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{produtos.valor}</td>
                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{produtos.total_produto}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <th className="text-center whitespace-nowrap px-3 py-2.5 text-sm text-gray-700">Total Venda:</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th className="whitespace-nowrap px-3 py-2.5 text-sm text-gray-700">{impressao.addons.total}</th>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      }
                      {impressao.obs && impressao.obs.office.devices.quantity &&
                        <>
                          <div className="text-sm text-center mt-8 flex">
                            <strong>SoC As a Services Microsoft</strong>
                          </div>
                          <div className="mt-5 flow-root">
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                  <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                      <tr className='grid grid-cols-10'>
                                        <th scope="col" className="col-span-6 px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                          Serviço
                                        </th>
                                        <th scope="col" className="col-span-2 px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                          Usuários                                    </th>
                                        <th scope="col" className="col-span-1 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                          EPS
                                        </th>
                                        <th scope="col" className="col-span-1 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                          GB/day
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                      <tr className='grid grid-cols-10' key={`office`}>
                                        <td className="col-span-6 text-center px-3 py-4 whitespace-nowrap text-sm text-gray-500">Office 365</td>
                                        <td className="col-span-2 whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{impressao.obs.office.devices.quantity}</td>
                                        <td className="col-span-1 whitespace-nowrap truncate max-w-[250px] px-3 py-4 text-sm text-gray-500">{impressao.obs.office.devices.eps}</td>
                                        <td className="col-span-1 whitespace-nowrap px-3 py-4 text-sm text-gray-500">{impressao.obs.office.devices.gb}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      }

                      {impressao.obs && impressao.obs.analytics.devices.length > 0 &&
                        <>
                          <div className="text-sm text-center mt-8 flex">
                            <strong>SoC As a Services Analytics & Reports</strong>
                          </div>
                          <div className="mt-5 flow-root">
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                  <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                      <tr className='grid grid-cols-10'>
                                        <th scope="col" className="col-span-6 px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                          Devices
                                        </th>
                                        <th scope="col" className="col-span-2 px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                          Qtde
                                        </th>
                                        <th scope="col" className="col-span-1 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                          EPS
                                        </th>
                                        <th scope="col" className="col-span-1 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                          GB/day
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                      {impressao.obs.analytics.devices.map((produtos) => (
                                        <tr className='grid grid-cols-10' key={produtos.id}>
                                          <td className="col-span-6 text-center px-3 py-4 whitespace-nowrap text-sm text-gray-500">{produtos.nome}</td>
                                          <td className="col-span-2 whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{produtos.quantity}</td>
                                          <td className="col-span-1 whitespace-nowrap truncate max-w-[250px] px-3 py-4 text-sm text-gray-500">{produtos.eps}</td>
                                          <td className="col-span-1 whitespace-nowrap px-3 py-4 text-sm text-gray-500">{produtos.gb}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      }


                      {impressao.obs && impressao.obs.light.devices.length > 0 &&
                        <>
                          <div className="text-sm text-center mt-8 flex">
                            <strong>SoC As a Services Light</strong>
                          </div>
                          <div className="mt-5 flow-root">
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                  <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                      <tr className='grid grid-cols-10'>
                                        <th scope="col" className="col-span-6 px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                          Devices
                                        </th>
                                        <th scope="col" className="col-span-2 px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                          Qtde
                                        </th>
                                        <th scope="col" className="col-span-1 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                          EPS
                                        </th>
                                        <th scope="col" className="col-span-1 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                          GB/day
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                      {impressao.obs.light.devices.map((produtos) => (
                                        <tr className='grid grid-cols-10' key={produtos.id}>
                                          <td className="col-span-6 text-center px-3 py-4 whitespace-nowrap text-sm text-gray-500">{produtos.nome}</td>
                                          <td className="col-span-2 whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{produtos.quantity}</td>
                                          <td className="col-span-1 whitespace-nowrap truncate max-w-[250px] px-3 py-4 text-sm text-gray-500">{produtos.eps}</td>
                                          <td className="col-span-1 whitespace-nowrap px-3 py-4 text-sm text-gray-500">{produtos.gb}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      }

                      {impressao.obs && impressao.obs.full.devices.length > 0 &&
                        <>
                          <div className="text-sm text-center mt-8 flex">
                            <strong>SoC As a Services Full</strong>
                          </div>
                          <div className="mt-5 flow-root">
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                  <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                      <tr className='grid grid-cols-10'>
                                        <th scope="col" className="col-span-6 px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                          Devices
                                        </th>
                                        <th scope="col" className="col-span-2 px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                          Qtde
                                        </th>
                                        <th scope="col" className="col-span-1 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                          EPS
                                        </th>
                                        <th scope="col" className="col-span-1 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                          GB/day
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                      {impressao.obs.full.devices.map((produtos) => (
                                        <tr className='grid grid-cols-10' key={produtos.id}>
                                          <td className="col-span-6 text-center px-3 py-4 whitespace-nowrap text-sm text-gray-500">{produtos.nome}</td>
                                          <td className="col-span-2 whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{produtos.quantity}</td>
                                          <td className="col-span-1 whitespace-nowrap truncate max-w-[250px] px-3 py-4 text-sm text-gray-500">{produtos.eps}</td>
                                          <td className="col-span-1 whitespace-nowrap px-3 py-4 text-sm text-gray-500">{produtos.gb}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      }

                      <div className="grid grid-cols-6 gap-6 text-sm mt-5">
                        <strong className='col-span-6 sm:col-span-2'>Validade da Proposta: 14 dias</strong>
                      </div>

                    </div> : <div className='mt-4 mb-4 flex justify-center'>
                      <svg aria-hidden="true" className="relative inline w-[60px] h-[60px] text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                      </svg>
                    </div>}
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={exportPdf}
                    >
                      Download
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>


  )
}
