import axios from "axios";
import authService from "services/auth.service";
import logout from "./logout-helper";
import { trackPromise } from 'react-promise-tracker';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

instance.interceptors.request.use(
  (config) => {
    const authUser = authService.getAuthUser();
    if (authUser) {
      config.headers["authorization"] = `Bearer ${authUser.access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error?.response?.status === 401) {
      await logout()
      localStorage.removeItem("authUser");
      window.location.reload();
    } else {
      return Promise.reject(error.response);
    }
  }
);

const get = (url, params, config = {}) => trackPromise(instance.get(url, { params, ...config }));
const post = (url, data, config = {}) => trackPromise(instance.post(url, data, config));
const del = (url, data, config = {}) => trackPromise(instance.delete(url, data, config));

const methods = { get, post, del };

export default methods;
