import React, { useState } from 'react';

const NotificationContext = React.createContext({
  notification: null,
  notificationText: null,
  success: () => { },
  error: () => { },
});

const STATES = {
  SUCCESS: 'success',
  ERROR: 'error',
};

const NotificationProvider = (props) => {
  const [notification, setNotification] = useState(null);
  const [show, setShow] = useState(false);
  const [notificationText, setNotificationText] = useState(null);
  const success = (text) => {
    setNotificationText(text);
    setNotification(STATES.SUCCESS);
    setShow(true)
    setTimeout(() => {
      setShow(false)
    }, 4000);
  };
  const error = (text) => {
    setNotificationText(text);
    setNotification(STATES.ERROR);
    setShow(true)
    setTimeout(() => {
      setShow(false)
    }, 4000);
    
  };
  const clear = () => {
    setNotificationText(null);
    setNotification(null);
  };
  return (
    <NotificationContext.Provider
      value={{
        success, error, clear, notification, notificationText, show
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
};

export { NotificationProvider };
export default NotificationContext;